import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MobileNumberInput from "../mobileNumberInput";
import { _sendOTPForResetPassword } from "../../api/auth";
import { notifyError, notifySuccess } from "../../utils/notification";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+237");

  const handleSendOtp = () => {
    let requestData = {
      countryCode: countryCode,
      mobile: mobile,
      email: "",
      deviceType: "WEB",
      sectionType: "RESET_PASSWORD",
      medium: "MOBILE",
    };

    try {
      _sendOTPForResetPassword(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          notifySuccess(t("OTP_Sent_For_Login"));
          navigate("/verify", {
            state: {
              mobile: mobile,
              countryCode: countryCode,
              previousPage: "forgetPassword",
            },
          });
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row custom-item-center">
          <div className="col-md-6 m-auto">
            <div className="forget-password custom-box-property py-4">
              <div className="forget-password_box">
                <div className="right-box">
                  <div className="text-center">
                    <span className="icon-box">
                      <LockOutlinedIcon />
                    </span>
                    <h3>{t("Forgot_password")}?</h3>
                    <p>{t("Forgot_password_msg")}</p>
                  </div>
                  <form action="">
                    <div className="row">
                      <div className="col-md-12">
                        <MobileNumberInput
                          setMobile={setMobile}
                          setCountryCode={setCountryCode}
                        />
                      </div>
                      <div className="col-md-6 mt-4 m-auto">
                        <div className="btn_primary" onClick={handleSendOtp}>
                          {t("Send_OTP")}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
