import common from "./common";
import axios from "axios";
import { notifyError } from "../utils/notification";
import { t } from "i18next";

export const SignUpAPI = (data) => {
  var authOptions = {
    method: "POST",
    url: common.SignUp,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "You are already registered, please try to login."
          ? t("Already_registered")
          : error.response.data.message
      );
    });
};

export const _verifyPhone = (data) => {
  var authOptions = {
    method: "POST",
    url: common.verifyPhone,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The OTP is wrong, please check and try again."
          ? t("OTP_wrong")
          : error.response.data.message === "OTP is required"
          ? t("OTP_required")
          : error.response.data.message ===
            "The OTP code you received has expired. Please press the RESEND button and try again."
          ? t("OTP_code_expired")
          : error.response.data.message
      );
    });
};

export const _sendMobileOTPonLogin = (data) => {
  var authOptions = {
    method: "POST",
    url: common.sendMobileOTPonLogin,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The password you entered is incorrect. You have 2 more attempts left before your account is temporarily locked out."
          ? t("password_incorrect_2_attempt")
          : error.response.data.message ===
            "The password you entered is incorrect. You have 1 more attempts left before your account is temporarily locked out."
          ? t("password_incorrect_1_attempt")
          : error.response.data.message ===
            "You have been blocked from performing this action for 1 hour due to multiple failed password attempts. Please try again later or contact our support."
          ? t("password_incorrect_blocked")
          : error.response.data.message ===
            "Password can be from 6-20 Characters only"
          ? t("password_length")
          : error.response.data.message ===
            "Mobile should be 7-11 Characters only"
          ? t("Mobile_length_error")
          : error.response.data.message ===
            "No account found with that phone number. Please try again."
          ? t("No_account_found")
          : error.response.data.message ===
            "You have been blocked for resend OTP . Please try after 15 minutes."
          ? t("Blocked_resend_OTP")
          : error.response.data.message ===
            "You have been blocked from performing this action for 1 hour due to multiple failed OTP verification attempts. Please try again later or contact our support."
          ? t("Blocked_multiple_failed_OTP")
          : error.response.data.message === "Password should be alphanumeric"
          ? t("Password_alphanumeric")
          : error.response.data.message
      );
    });
};

export const _verify2FAlogin = (data) => {
  var authOptions = {
    method: "PATCH",
    url: common.verify2FAlogin,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The OTP is wrong, please check and try again."
          ? t("OTP_wrong")
          : error.response.data.message === "OTP is required"
          ? t("OTP_required")
          : error.response.data.message ===
            "The OTP code you received has expired. Please press the RESEND button and try again."
          ? t("OTP_code_expired")
          : error.response.data.message
      );
    });
};

export const _loginMobile = (data) => {
  var authOptions = {
    method: "POST",
    url: common.loginMobile,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The password you entered is incorrect. You have 2 more attempts left before your account is temporarily locked out."
          ? t("password_incorrect_2_attempt")
          : error.response.data.message ===
            "The password you entered is incorrect. You have 1 more attempts left before your account is temporarily locked out."
          ? t("password_incorrect_1_attempt")
          : error.response.data.message ===
            "You have been blocked from performing this action for 1 hour due to multiple failed password attempts. Please try again later or contact our support."
          ? t("password_incorrect_blocked")
          : error.response.data.message ===
            "Password can be from 6-20 Characters only"
          ? t("password_length")
          : error.response.data.message ===
            "Mobile should be 7-11 Characters only"
          ? t("Mobile_length_error")
          : error.response.data.message ===
            "No account found with that phone number. Please try again."
          ? t("No_account_found")
          : error.response.data.message
      );
    });
};

export const _changePassword = (data) => {
  var authOptions = {
    method: "PATCH",
    url: common.changePassword,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "Password can be from 6-20 Characters only"
          ? t("password_length")
          : error.response.data.message ===
            "The Old password is wrong, please check and try again."
          ? t("Old_password_wrong")
          : error.response.data.message
      );
    });
};

export const _sendOTPForResetPassword = (data) => {
  var authOptions = {
    method: "POST",
    url: common.sendOtpResetPassword,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "No account found with that phone number. Please try again."
          ? t("No_account_found")
          : error.response.data.message
      );
    });
};

export const _verifyOtpResetPassword = (data) => {
  var authOptions = {
    method: "PATCH",
    url: common.verifyOtpResetPassword,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The OTP is wrong, please check and try again."
          ? t("OTP_wrong")
          : error.response.data.message === "OTP is required"
          ? t("OTP_required")
          : error.response.data.message ===
            "The OTP code you received has expired. Please press the RESEND button and try again."
          ? t("OTP_code_expired")
          : error.response.data.message
      );
    });
};

export const _resetPassword = (data) => {
  var authOptions = {
    method: "POST",
    url: common.resetPassword,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _resendSignupCode = (data) => {
  var authOptions = {
    method: "POST",
    url: common.resendSignupCode,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The OTP is wrong, please check and try again."
          ? t("OTP_wrong")
          : error.response.data.message === "OTP is required"
          ? t("OTP_required")
          : error.response.data.message ===
            "The OTP code you received has expired. Please press the RESEND button and try again."
          ? t("OTP_code_expired")
          : error.response.data.message
      );
    });
};

export const _validateToken = () => {
  var authOptions = {
    method: "GET",
    url: common.validateToken,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _checkOtpStatus = (data) => {
  var authOptions = {
    method: "POST",
    url: common.checkOtpStatus,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The password you entered is incorrect. You have 2 more attempts left before your account is temporarily locked out."
          ? t("password_incorrect_2_attempt")
          : error.response.data.message ===
            "The password you entered is incorrect. You have 1 more attempts left before your account is temporarily locked out."
          ? t("password_incorrect_1_attempt")
          : error.response.data.message ===
            "You have been blocked from performing this action for 1 hour due to multiple failed password attempts. Please try again later or contact our support."
          ? t("password_incorrect_blocked")
          : error.response.data.message ===
            "Password can be from 6-20 Characters only"
          ? t("password_length")
          : error.response.data.message ===
            "Mobile should be 7-11 Characters only"
          ? t("Mobile_length_error")
          : error.response.data.message ===
            "No account found with that phone number. Please try again."
          ? t("No_account_found")
          : error.response.data.message ===
            "You have been blocked for resend OTP . Please try after 15 minutes."
          ? t("Blocked_resend_OTP")
          : error.response.data.message ===
            "You have been blocked from performing this action for 1 hour due to multiple failed OTP verification attempts. Please try again later or contact our support."
          ? t("Blocked_multiple_failed_OTP")
          : error.response.data.message === "Password should be alphanumeric"
          ? t("Password_alphanumeric")
          : error.response.data.message
      );
    });
};
