import { useTranslation } from "react-i18next";

export default function CardBox(props) {
  const { t } = useTranslation();

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return (
    <>
      <div className="info-card">
        <div className="card-top">
          <div className="card-heading">{props.heading}</div>
          <div className="card-icon">
            <i className={props.icon} aria-hidden="true"></i>
          </div>
        </div>
        <div className="card-bottom">
          {props.cardName === "TotalUsers" ? (
            <div className="card-value">{props.value}</div>
          ) : null}
          {props.cardName === "NewRequest" ? (
            <div className="card-value">{props.value}</div>
          ) : null}
          {props.cardName === "InflationRate" ? (
            <div className="card-value">
              {props.value} % {t("per_year")}
            </div>
          ) : null}
          {props.cardName === "TotalAmount" ? (
            <div className="card-value">
              {props.value}
              {/* {parseFloat(props.value).toFixed(2)} F CFA{" "} */}
              {/* {props.value.toLocaleString("en-US", formatting_options)} F CFA{" "} */}
            </div>
          ) : null}
          {props.cardName === "TotalInterest" ? (
            <div className="card-value">
              {props.value}
              {/* {props.value.toLocaleString("en-US", formatting_options)} F CFA{" "} */}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
