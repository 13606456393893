import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { _getUserDetails } from "../api/user";

export default function Sidebar(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSubNav, setShowSubNav] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setIsAdmin(res.data.data.role === "ADMIN" ? true : false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleHome = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  const handleAbout = () => {
    navigate("/about");
  };

  useEffect(() => {
    if (sessionStorage.getItem("Token")) {
      setIsLoggedIn(true);
      getUserProfile();
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // useEffect(() => {
  // });

  const showSubMenu = () => {
    setShowSubNav(!showSubNav);
  };

  return (
    <>
      <div
        className={`left-side side-navigation navigation ${
          props.isActive ? "jsActive" : ""
        }`}
      >
        <div className="logo pd_20">
          <span className="custom-cursor_pointer" onClick={handleHome}>
            MBOA
          </span>
        </div>
        <div className="mobile-menus">
          <span onClick={handleHome}>{t("Home")}</span>
          <span onClick={handleAbout}>{t("About_us")}</span>
        </div>
        <ul>
          <NavLink
            to="/dashboard"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <li>
              <div className="parent">
                <span className="icon">
                  {isAdmin ? (
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-money" aria-hidden="true"></i>
                  )}
                </span>
                <span className="title">
                  {isAdmin ? t("Dashboard") : t("Wallet")}
                </span>
              </div>
            </li>
          </NavLink>
          {isAdmin ? (
            <NavLink
              to="/usersList"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-users" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Users_List")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {isAdmin ? (
            <NavLink
              to="/depositToUser"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-money" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Deposit_to_users")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {isAdmin ? (
            <NavLink
              to="/notifications"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Notifications")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {isAdmin ? (
            <NavLink
              to="/inflationRate"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-bar-chart" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Set_Interest_Rate")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {isAdmin ? (
            <NavLink
              to="/transactionStatus"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-hourglass-start" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Set_Transaction_Status")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {isAdmin ? (
            <NavLink
              to="/transactionHistory"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-history" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Transaction_History")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {!isAdmin ? (
            <NavLink
              to="/depositMoney"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-plus-square-o" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("DEPOSIT_MONEY")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {!isAdmin ? (
            <NavLink
              to="/withdrawMoney"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-exchange" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("WITHDRAW_MONEY")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {isAdmin ? (
            <NavLink
              to="/manageMoneyProvider"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-exchange" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Mobile_Money_Provider")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {isAdmin ? (
            <NavLink
              to="/manageFooter"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                  <span className="title">{t("Manage_Footer")}</span>
                </div>
              </li>
            </NavLink>
          ) : null}
          {/* {!isAdmin ? (
            <NavLink
              to="/changesHistory"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <li>
                <div className="parent">
                  <span className="icon">
                    <i className="fa fa-exchange" aria-hidden="true"></i>
                  </span>
                  <span className="title">Profile Changes History</span>
                </div>
              </li>
            </NavLink>
          ) : null} */}
          <li onClick={showSubMenu} className="submenu-parent">
            <div className="parent">
              <span className="icon">
                <i className="fa fa-wrench" aria-hidden="true"></i>
              </span>
              <span className="title">
                {t("Settings")}{" "}
                <span className="btn-settings">
                  <i
                    aria-hidden="true"
                    className={`${
                      showSubNav ? "fa fa-plus-circle" : "fa fa-minus-circle"
                    }`}
                  ></i>
                </span>
              </span>
            </div>
          </li>
          <NavLink
            to="/profile"
            className={`${showSubNav ? "hide-menus" : "show-menus"} ${({
              isActive,
            }) => (isActive ? "active" : "inactive")}`}
            onClick={() => props.setProfile("self")}
          >
            <li>
              <div className="parent sidebar-children">
                <span className="icon">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>
                <span className="title">{t("Profile_Sidebar")}</span>
              </div>
            </li>
          </NavLink>
          <NavLink
            to="/changePassword"
            className={`${showSubNav ? "hide-menus" : "show-menus"} ${({
              isActive,
            }) => (isActive ? "active" : "inactive")}`}
          >
            <li>
              <div className="parent sidebar-children">
                <span className="icon">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
                <span className="title">{t("Change_Password")}</span>
              </div>
            </li>
          </NavLink>
        </ul>
      </div>
    </>
  );
}
