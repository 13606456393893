import { Outlet, Navigate } from "react-router-dom";
import { _validateToken } from "../api/auth";
import React, { useEffect, useState } from "react";

const PrivateRoutes = () => {
  const [isAuthentic, setIsAuthentic] = useState(true);

  const isUserAuthenticated = () => {
    if (sessionStorage.getItem("Token")) {
      try {
        _validateToken().then(async (res) => {
          if (res && res.data.status === 200 && res.data.success) {
            setIsAuthentic(true);
          } else {
            console.log("Something went wrong");
            setIsAuthentic(false);
          }
        });
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      setIsAuthentic(false);
    }
  };

  useEffect(() => {
    isUserAuthenticated();
  }, []);

  return isAuthentic ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
