import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../../sidebar";
import Header1 from "../../header1";
import {
  _getAdminDashboardInfo,
  _updateInterestRate,
} from "../../../api/admin";
import { notifySuccess, notifyError } from "../../../utils/notification";
import Footer from "../Footer";
import LoaderLayer from "../../LoaderLayer";

function InflationRate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [currentInterestRate, setCurrentInterestRate] = useState();
  const [inflationRate, setInflationRate] = useState();
  const [activeLoader, setActiveLoader] = useState(true);
  const exceptThisSymbols = ["e", "E", "+", "-"];

  const getAdminDashboardInfo = () => {
    try {
      _getAdminDashboardInfo().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setCurrentInterestRate(res.data.data.inflationRate.yearlyInterest);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  useEffect(() => {
    getAdminDashboardInfo();
  }, []);

  const updateInterestRate = (e) => {
    e.preventDefault();
    try {
      if (!inflationRate) {
        notifyError(t("Enter_new_interest"));
      } else {
        if (inflationRate >= 100 || inflationRate <= 0) {
          notifyError(t("Interest_rate_limit"));
        } else {
          let requestData = {
            inflationRate: inflationRate,
          };
          _updateInterestRate(requestData).then(async (res) => {
            if (res && res.status === 200 && res.data.success) {
              notifySuccess(t("Interest_Rate_Success"));
              navigate("/dashboard");
            } else {
              console.log("Something went wrong");
            }
          });
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleInterestChange = (e) => {
    e.preventDefault();
    let num = e.target.value;
    if (num.length <= 5) {
      // setInflationRate(num);
      const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
      // setAmount(e.target.value.match(regex)[0]);
      setInflationRate(num.match(regex)[0]);
    }
  };

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <div className="change-password_page  custom-box-property">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <h4 className="page-heading">{t("Set_Interest_Rate")}</h4>
                  <form action="" noValidate>
                    <div className="row g-3">
                      <div className="col-md-12">
                        <label htmlFor="">
                          {t("Current_Interest_Rate_is")}: {currentInterestRate}{" "}
                          % {t("per_year")}
                        </label>
                        <label htmlFor="">
                          {t("Enter_New_Interest_Rate")} (% {t("per_year")}):
                        </label>
                        <input
                          required
                          name="inflationRate"
                          type="number"
                          id="inflationRate"
                          autoComplete="inflation-rate"
                          value={inflationRate}
                          placeholder="0 - 100"
                          onChange={(e) => {
                            handleInterestChange(e);
                          }}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6 m-auto text-center">
                        <button
                          className="btn_primary"
                          onClick={updateInterestRate}
                        >
                          {t("Set_Interest_Rate")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default InflationRate;
