import common from "./common";
import axios from "axios";
import { notifyError } from "../utils/notification";
import { t } from "i18next";

export const _getAdminDashboardInfo = (data) => {
  var authOptions = {
    method: "GET",
    url: common.getAdminDashboardInfo,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getUserList = (data) => {
  var authOptions = {
    method: "GET",
    url: `${common.userList}?skip=${data.skip}&limit=${data.limit}&start=0&end`,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getActiveUserList = () => {
  var authOptions = {
    method: "GET",
    url: common.getActiveUserList,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getUserHistory = (userId, data) => {
  var authOptions = {
    method: "GET",
    url: `${common.userHistory}?skip=${data.skip}&limit=${data.limit}&start&end&userId=${userId}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _depositBalance = (data) => {
  var authOptions = {
    method: "POST",
    url: common.DepositToUser,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "User is blocked, please contact support."
          ? t("User_blocked_contact_support")
          : error.response.data.message === "Amount is required"
          ? t("Amount_required")
          : error.response.data.message
      );
    });
};

export const _getUserTransactionHistoryForAdmin = (data) => {
  var authOptions = {
    method: "GET",
    url: `${common.getUserTransactionHistoryForAdmin}?skip=${data.skip}&limit=${data.limit}&start&end&userId=${data.userId}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};

export const _getUserDetailsForAdmin = (userId) => {
  var authOptions = {
    method: "GET",
    url: `${common.getUserDetailsForAdmin}/${userId}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};

export const _updateUserProfile = (data) => {
  var authOptions = {
    method: "POST",
    url: common.updateUserProfile,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The provided email is not valid, please check and try again."
          ? t("Invalid_email")
          : error.response.data.message
      );
    });
};

export const _getAdminNotification = (data) => {
  var authOptions = {
    method: "GET",
    url: `${common.adminNotification}?pageNo=${data.pageNo}&limit=${data.limit}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getAdminTransactions = (data) => {
  var authOptions = {
    method: "GET",
    url: `${common.getAdminTransactions}?skip=${data.skip}&limit=${data.limit}&start&end`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};

export const _updateInterestRate = (data) => {
  var authOptions = {
    method: "POST",
    url: common.updateInterestRate,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _getUserRequests = (data) => {
  var authOptions = {
    method: "GET",
    url: `${common.getUserRequests}?pageNo=${data.pageNo}&limit=${data.limit}&approved=${data.approved}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};

export const _changeUserRequestStatus = (data) => {
  var authOptions = {
    method: "POST",
    url: common.changeUserRequestStatus,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _blockUnblockUser = (data) => {
  var authOptions = {
    method: "PATCH",
    url: common.blockUnblockUser,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _markNotificationAsRead = (data) => {
  var authOptions = {
    method: "PATCH",
    url: common.markNotificationAsRead,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _markAllAsRead = () => {
  var authOptions = {
    method: "PATCH",
    url: common.markAllAsRead,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getScreenInstructions = (language) => {
  var authOptions = {
    method: "GET",
    url: `${common.getScreenInstructions}?type=${language}`,
    headers: {
      Accept: "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};

export const _updateScreenInstructions = (data) => {
  var authOptions = {
    method: "POST",
    url: common.updateScreenInstructions,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getScreenList = (language) => {
  var authOptions = {
    method: "GET",
    url: `${common.getScreenList}?type=${language}`,
    headers: {
      Accept: "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};

export const _getGraphData = (category, type) => {
  var authOptions = {
    method: "GET",
    url: common.getGraphData,
    url: `${common.getGraphData}/${category}?type=${type}`,
    headers: {
      Accept: "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };

  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      // notifyError(error.response.data.message);
    });
};
