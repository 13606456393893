import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../sidebar";
import Header1 from "../header1";
import { notifyError, notifySuccess } from "./../../utils/notification";
import { _changePassword } from "../../api/auth";
import Footer from "./Footer";

function ChangePassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const updatePassword = (e) => {
    e.preventDefault();
    if (!currentPassword || !newPassword || !confirmPassword) {
      notifyError(t("Enter_all_details"));
    } else {
      if (newPassword === confirmPassword) {
        try {
          let requestData = {
            oldPassword: currentPassword,
            newPassword: newPassword,
          };

          _changePassword(requestData).then(async (res) => {
            if (res && res.status === 200 && res.data.success) {
              notifySuccess(t("Change_password_success"));
              navigate("/dashboard");
            } else {
              console.log("Something went wrong");
            }
          });
        } catch (error) {
          console.log("Error:", error);
        }
      } else {
        notifyError(t("Password_does_not_match"));
      }
    }
  };

  const closeChangePassword = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <div className="change-password_page  custom-box-property">
              <div className="row">
                <div className="col-md-6 m-auto">
                  <h4 className="page-heading">{t("Change_Password")}</h4>
                  <form action="" noValidate>
                    <div className="row g-3">
                      <div className="col-md-12">
                        <label htmlFor="">{t("Current_Password")}</label>
                        <input
                          required
                          name="currentPassword"
                          type="Password"
                          id="currentPassword"
                          autoComplete="current-password"
                          placeholder={t("Current_Password")}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="">{t("New_Password")}</label>
                        <input
                          required
                          name="newPassword"
                          type="Password"
                          id="newPassword"
                          autoComplete="new-password"
                          placeholder={t("New_Password")}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="">{t("Confirm_Password")}</label>
                        <input
                          required
                          name="confirmPassword"
                          label={t("Confirm_Password")}
                          type="Password"
                          id="confirmPassword"
                          autoComplete="confirm-password"
                          placeholder={t("Confirm_Password")}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6">
                        <div
                          className="btn_secondary"
                          onClick={closeChangePassword}
                        >
                          {t("Cancel")}
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          className="btn_primary"
                          onClick={(e) => updatePassword(e)}
                        >
                          {t("Update")}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
