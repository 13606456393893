import React from "react";
import SignUp from "./components/pages/SignUp";
import LogIn from "./components/pages/LogIn";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VerifyNumber from "./components/pages/VerifyNumber";
import Dashboard from "./components/layout";
import Welcome from "./components/pages/welcome";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ChangePassword from "./components/pages/ChangePassword";
import DepositMoney from "./components/pages/DepositMoney";
import WithdrawMoney from "./components/pages/WithdrawMoney";
import TransactionHistory from "./components/pages/TransactionHistory";
import Profile from "./components/pages/profile";
import UsersList from "./components/pages/admin/UsersList";
import Notifications from "./components/pages/admin/Notifications";
import InflationRate from "./components/pages/admin/InflationRate";
import TransactionStatus from "./components/pages/admin/TransactionStatus";
import ManageMoneyProvider from "./components/pages/admin/manageMoneyProvider";
import ChangesHistory from "./components/pages/changesHistory";
import NewMoneyProvider from "./components/pages/admin/newMoneyProvider";
import { ConfirmProvider } from "material-ui-confirm";
import DepositToUser from "./components/pages/admin/depositToUser";
import LoginAdmin from "./components/pages/admin/loginAdmin";
import ForgetPassword from "./components/pages/forgetPassword";
import ResetPassword from "./components/pages/resetPassword";
import About from "./components/pages/about";
import TermsConditions from "./components/pages/TermsConditions";
import FAQ from "./components/pages/FAQ";
import ContactUs from "./components/pages/ContactUs";
import ManageFooter from "./components/pages/admin/ManageFooter";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import PrivateRoutes from "./utils/PrivateRoutes";

function App() {
  return (
    <>
      <ToastContainer />
      <ConfirmProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="*" element={<About />} />
              <Route path="/" exact element={<Welcome />} />
              <Route path="/login" exact element={<LogIn />} />
              <Route exact path="/signup" element={<SignUp />} />
              <Route path="/about" exact element={<About />} />
              <Route
                path="/termsConditions"
                exact
                element={<TermsConditions />}
              />
              <Route path="/FAQ" exact element={<FAQ />} />
              <Route path="/contactUs" exact element={<ContactUs />} />
              <Route path="/privacyPolicy" exact element={<PrivacyPolicy />} />
              <Route exact path="/verify" element={<VerifyNumber />} />
              <Route exact path="/admin" element={<LoginAdmin />} />
              <Route
                exact
                path="/forgetPassword"
                element={<ForgetPassword />}
              />
              <Route exact path="/resetPassword" element={<ResetPassword />} />

              <Route element={<PrivateRoutes />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route
                  exact
                  path="/changePassword"
                  element={<ChangePassword />}
                />
                <Route exact path="/depositMoney" element={<DepositMoney />} />
                <Route
                  exact
                  path="/withdrawMoney"
                  element={<WithdrawMoney />}
                />
                <Route
                  exact
                  path="/transactionHistory"
                  element={<TransactionHistory />}
                />
                <Route path="/profile" element={<Profile />} />
                <Route exact path="/usersList" element={<UsersList />} />
                <Route
                  exact
                  path="/notifications"
                  element={<Notifications />}
                />
                <Route
                  exact
                  path="/inflationRate"
                  element={<InflationRate />}
                />
                <Route
                  exact
                  path="/transactionStatus"
                  element={<TransactionStatus />}
                />
                <Route
                  exact
                  path="/manageMoneyProvider"
                  element={<ManageMoneyProvider />}
                />
                <Route
                  exact
                  path="/changesHistory"
                  element={<ChangesHistory />}
                />
                <Route
                  exact
                  path="/newMoneyProvider"
                  element={<NewMoneyProvider />}
                />
                <Route
                  exact
                  path="/depositToUser"
                  element={<DepositToUser />}
                />
                <Route path="/manageFooter" exact element={<ManageFooter />} />
              </Route>
            </Routes>
          </div>
        </Router>
      </ConfirmProvider>
    </>
  );
}

export default App;
