import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MobileNumberInput from "../../mobileNumberInput";
import sideImg from "../../../assets/images/admin-login-icon.jpg";
import { _loginMobile } from "../../../api/auth";
import { notifyError, notifySuccess } from "../../../utils/notification";
// import { FormControlLabel, Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginAdmin = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+234");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [adminPassword, setAdminPassword] = useState("");

  const verifyNumberForAdminLogin = () => {
    try {
      if (mobile.length === 0 || adminPassword.length === 0) {
        notifyError(t("Enter_all_details"));
      } else {
        let loginData = {
          requestId: "",
          countryCode: countryCode,
          mobile: mobile,
          password: adminPassword,
          medium: "MOBILE",
          deviceType: "WEB",
          sectionType: "LOGIN",
        };
        _loginMobile(loginData).then(async (response) => {
          if (response && response.status === 200 && response.data.success) {
            sessionStorage.setItem("MobileNo", mobile);
            sessionStorage.setItem("Token", response.data.data.token);
            notifySuccess(t("Login_success_mobile"));
            navigate("/dashboard");
          } else {
            console.log("Something went wrong");
          }
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="login-page py-3">
          <div className="login-page_box">
            <div className="row gx-0 h-100">
              <div className="col-md-6">
                <div className="image-box d-none d-md-block">
                  <img className="object_contain" src={sideImg} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="right-box">
                  <div className="text-center">
                    <span className="icon-box">
                      <LockOutlinedIcon />
                    </span>

                    <h3>{t("Admin_Login")}</h3>
                  </div>
                  <form className={classes.form} noValidate autoComplete="off">
                    <MobileNumberInput
                      setMobile={setMobile}
                      setCountryCode={setCountryCode}
                    />
                    <label htmlFor="password" className="mt-4">
                      {t("Enter_Password")}:{" "}
                    </label>
                    <input
                      required
                      error={isPasswordError}
                      placeholder="********"
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={(e) => {
                        setAdminPassword(e.target.value);
                        if (e.target.value.length === 0) {
                          setIsPasswordError(true);
                        } else {
                          setIsPasswordError(false);
                        }
                      }}
                    />
                    <br />
                    <br />
                    {/* <div className="final-checkbox">
                      <FormControlLabel
                        className="m-0"
                        control={
                          <Checkbox
                            value="remember"
                            className="p-0"
                            color="primary"
                          />
                        }
                      />
                      <div className="final-text">Remember me</div>
                    </div> */}
                    <div
                      type="submit"
                      className={`${classes.submit} btn_primary w-50 m-auto`}
                      onClick={(e) => verifyNumberForAdminLogin(e)}
                    >
                      {t("Login")}
                    </div>
                    {/* <div className="row mt-3">
                      <div className="col-md-5">
                        <div className="forget-password">
                          <NavLink to="/" variant="body2">
                            {t("Forgot_password")}?
                          </NavLink>
                        </div>
                      </div>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginAdmin;
