import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

function NotificationCard({ type, notification, setSelectedNotification }) {
  const { t } = useTranslation();

  const markNotification = () => {
    setSelectedNotification(notification._id);
  };

  return (
    <>
      <div
        className={`notification-card ${
          type === "error"
            ? "warning_msg"
            : type === "success"
            ? "success_msg"
            : "pending_msg"
        }`}
      >
        <div className="notification-icon">
          <i
            className={` ${
              type === "error"
                ? "fa fa-exclamation"
                : type === "success"
                ? "fa fa-check"
                : "fa fa-info"
            }`}
            aria-hidden="true"
          ></i>
        </div>
        <div className="notification-content">
          {/* <div className="notification-text">{notification.message}</div> */}
          <div className="notification-text">
            {t("withdraw_notification")} {notification.amount}
          </div>
          <div className="notifiaction-time">
            {moment(notification.createdAt).format("llll")}
          </div>
        </div>
        <div className="read-icon" onClick={markNotification}>
          <i className="fa fa-envelope-open-o" aria-hidden="true"></i>
        </div>
      </div>
    </>
  );
}

export default NotificationCard;
