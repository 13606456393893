import { useState } from "react";
import Header1 from "./header1";
// import InflationChart from "./dashboard/chart";
import Sidebar from "./sidebar";
import Dashboard from "./dashboard/dashboard";
import Footer from "./pages/Footer";

export default function Layout() {
  const [isActive, setActive] = useState(false);

  return (
    <>
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <Dashboard />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
