import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Header1 from "../header1";
import Sidebar from "../sidebar";
import { _getUserDetails, _updateProfile } from "../../api/user";
import { _getProviders } from "../../api/providers";
import { notifySuccess, notifyError } from "../../utils/notification";
import { _getUserDetailsForAdmin, _updateUserProfile } from "../../api/admin";
import Footer from "./Footer";
import LoaderLayer from "../LoaderLayer";
import { useConfirm } from "material-ui-confirm";

export default function Profile() {
  const { t } = useTranslation();
  const location = useLocation();
  const userId = location && location.state ? location.state.userId : "";
  const [isEdit, setIsEdit] = useState(true);
  const [btnValue, setBtnValue] = useState("Edit");
  const [isActive, setActive] = useState(false);
  const [mobileNo, setMobileNo] = useState();
  const [provider, setProvider] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [dob, setDOB] = useState();
  const [address, setAddress] = useState();
  const [values, setValues] = useState([]);
  const [activeLoader, setActiveLoader] = useState(true);
  const [isAdmin, setisAdmin] = useState(false);
  const [profile, setProfile] = useState();
  const [editBalanceAccess, setEditBalanceAccess] = useState(false);
  const [acountBalance, setAccountBalance] = useState();
  const [editBalance, setEditBalance] = useState();
  const [editBalanceType, setEditBalanceType] = useState();
  const [editBalanceRemark, setEditBalanceRemark] = useState();
  const [userData, setUserData] = useState([]);
  const confirmSave = useConfirm();

  const editProfile = () => {
    if (btnValue === "Save") {
      try {
        let requestData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          requestId: "",
          medium: "MOBILE",
          dateOfBirth: dob,
          address: address,
          moneyProviderId: provider,
          moneyProviderMobile: mobileNo,
          medium: "EMAIL",
        };
        setActiveLoader(true);
        _updateProfile(requestData).then(async (res) => {
          if (res && res.data.status === 200 && res.data.success) {
            notifySuccess(t("Profile_edit_success"));
            setIsEdit(!isEdit);
            setBtnValue("Edit");
            setActiveLoader(false);
          } else {
            console.log("Something went wrong");
            setActiveLoader(false);
            setIsEdit(!isEdit);
            setBtnValue("Edit");
            getProvidersList();
            if (userId) {
              getUserDetailsForAdmin();
            } else {
              getLoggedUserProfile();
            }
          }
        });
      } catch (error) {
        console.log("Error:", error);
        setActiveLoader(false);
      }
    } else {
      setIsEdit(!isEdit);
      setBtnValue("Save");
    }
  };

  const getLoggedUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setMobileNo(res.data.data.mobile);
          setProvider(res.data.data.moneyProviderId);
          setFirstName(res.data.data.firstName);
          setLastName(res.data.data.lastName);
          setEmail(res.data.data.email);
          setDOB(moment(res.data.data.dob).format("YYYY-MM-DD"));
          setAddress(res.data.data.address);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const getCurrentprofileDetails = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setisAdmin(res.data.data.role === "ADMIN" ? true : false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  useEffect(() => {
    getCurrentprofileDetails();
  }, []);

  const handleChange = (event) => {
    setProvider(event.target.value);
  };

  const getProvidersList = () => {
    try {
      _getProviders().then(async (res) => {
        if (res.status === 200) {
          setValues(res.data.data);
          setProvider(res.data.data[0]._id);
        } else {
          setValues([]);
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getProvidersList();
    if (userId) {
      getUserDetailsForAdmin();
    } else {
      getLoggedUserProfile();
    }
  }, [profile, userData]);

  const getUserDetailsForAdmin = () => {
    try {
      _getUserDetailsForAdmin(userId).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setAccountBalance(res.data.data.balance);
          setMobileNo(res.data.data.mobile);
          setProvider(res.data.data.moneyProviderId);
          setFirstName(res.data.data.firstName);
          setLastName(res.data.data.lastName);
          setEmail(res.data.data.email);
          setDOB(moment(res.data.data.dob).format("YYYY-MM-DD"));
          setAddress(res.data.data.address);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const saveUserProfile = () => {
    if (btnValue === "Save") {
      try {
        let requestData = {
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          email: email,
          dob: moment(dob).valueOf(),
          address: address,
          moneyProviderId: provider,
          moneyProviderMobile: mobileNo,
          balance: editBalance,
          balanceType: editBalanceType,
          remark: editBalanceRemark,
        };
        setActiveLoader(true);
        _updateUserProfile(requestData).then(async (res) => {
          if (res && res.data.status === 200 && res.data.success) {
            notifySuccess(t("Profile_edit_success"));
            setIsEdit(!isEdit);
            setBtnValue("Edit");
            setActiveLoader(false);
          } else {
            console.log("Something went wrong");
            setActiveLoader(false);
          }
        });
      } catch (error) {
        console.log("Error:", error);
        setActiveLoader(false);
      }
    } else {
      setIsEdit(!isEdit);
      setBtnValue("Save");
    }
  };

  const editUserBalance = () => {
    try {
      let requestData = {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        dob: moment(dob).valueOf(),
        address: address,
        moneyProviderId: provider,
        moneyProviderMobile: mobileNo,
        balance: editBalance,
        balanceType: editBalanceType,
        remark: editBalanceRemark,
      };
      setActiveLoader(true);
      _updateUserProfile(requestData).then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          console.log("res in editUserBalance profile :", res);
          notifySuccess(t("BALANCE_UPDATED_SUCCESSFULLY"));
          setEditBalanceAccess(false);
          setUserData(res);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
          setActiveLoader(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const handleEditBalanceSave = () => {
    confirmSave({
      title: t("DO_YOU_REALLY_WANT_TO_UPDATE_BALANCE"),
      confirmationText: t("OK"),
      cancellationText: t("Cancel"),
    })
      .then(() => editUserBalance())
      .catch(() => notifyError(t("COULD_NOT_UPDATE_BALANCE")));
  };

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const exceptThisSymbols = ["e", "E", "+", "-"];

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar
          isActive={isActive}
          setActive={setActive}
          setProfile={setProfile}
        />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1
            setActive={setActive}
            isActive={isActive}
            setProfile={setProfile}
          />
          <div className="main-content">
            <div className="profile-page custom-box-property">
              <div className="row">
                <div className="col-md-10 m-auto">
                  {/* <h4 className="page-heading">{t("User_Information")}</h4> */}
                  {userId ? (
                    <form action="">
                      <div className="row g-2 g-md-3 mt-1">
                        <h4 className="page-heading">{t("BALANCE_INFORMATION")} </h4>
                        <div className="col-md-12">
                          {/* <label htmlFor="">{t("First_Name")}: </label> */}

                          <label htmlFor="">{t("USER_ACCOUNT_BALANCE")}</label>
                          <div className="user-balance-box">
                            <input
                              type="text"
                              placeholder="John"
                              value={Number(acountBalance).toLocaleString(
                                "en-US",
                                formatting_options
                              )}
                              disabled
                              onChange={(e) => {
                                setFirstName(fname);
                              }}
                              maxLength="20"
                            />
                            {isEdit ? (
                              <span
                                className="edit-balance-btn"
                                onClick={() => setEditBalanceAccess(true)}
                              >
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {editBalanceAccess ? (
                          <>
                            <div className="col-md-6">
                              <label htmlFor="">{t("Type")}:</label>
                              <select
                                name=""
                                id=""
                                onChange={(e) =>
                                  setEditBalanceType(e.target.value)
                                }
                              >
                                <option value="" disabled selected>
                                  {t("SELECT")}
                                </option>
                                <option value="ADD">{t("Deposite")}</option>
                                <option value="SUBSTRACT">{t("Withdraw")}</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="">{t("Enter_Amount")}:</label>
                              <input
                                type="number"
                                placeholder="1234"
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => {
                                  // exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault();
                                  // const regex = /([0-9, "e", "E", "+", "-"]*[\.|\,]{0,1}[0-9]{0,2})/s;
                                  const regex =
                                    /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
                                  setEditBalance(
                                    e.target.value.match(regex)[0]
                                  );
                                }}
                              />
                            </div>
                            <div className="col-md-12">
                              <label htmlFor="">{t("Remarks")}:</label>
                              <textarea
                                onChange={(e) =>
                                  setEditBalanceRemark(e.target.value)
                                }
                                placeholder="abcd"
                                name=""
                                id=""
                                cols="30"
                                rows="4"
                              ></textarea>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {editBalanceAccess ? (
                        <div className="row mt-3 justify-content-center">
                          <div className="col-md-3">
                            <div
                              className="btn_primary btn_cancel"
                              onClick={() => setEditBalanceAccess(false)}
                            >
                              {t("Cancel")}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className="btn_primary"
                              onClick={userId ? handleEditBalanceSave : ""}
                            >
                              {t("Save")}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  ) : (
                    ""
                  )}

                  <form action="">
                    <div className="row g-2 g-md-3 mt-1">
                      <h4 className="page-heading">{t("PROFILE_INFORMATION")}</h4>
                      <div className="col-md-6">
                        <label htmlFor="">{t("Mobile_Number")}:</label>
                        <input
                          type="text"
                          value={mobileNo && formatMobileNumber(mobileNo)}
                          disabled
                        />
                        <div className="col-md-12 d-flex align-items-center">
                          <span className="text-danger font-size-13 mx-1">
                            *{t("Warning_user_name")}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="">
                          {t("Change_Mobile_Money_Provider")}:{" "}
                        </label>
                        <select
                          name=""
                          id=""
                          disabled={isEdit ? true : false}
                          value={provider}
                          defaultValue={provider}
                          onChange={handleChange}
                        >
                          {values.map((value, index) => {
                            return (
                              <option key={index} value={value._id}>
                                {value.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div className="col-md-12 d-flex align-items-center">
                        <span className="text-danger font-size-13 mx-1">
                          *This is your user name and it can not be changed
                        </span>
                      </div> */}
                    </div>
                    <div className="row g-2 g-md-3 mt-1">
                      <div className="col-md-6">
                        <label htmlFor="">{t("First_Name")}: </label>
                        <input
                          type="text"
                          placeholder="John"
                          value={firstName}
                          disabled={isEdit ? true : false}
                          onChange={(e) => {
                            let fname = e.target.value;
                            fname = fname.replace(/[^A-Za-z]/gi, "");
                            setFirstName(fname);
                          }}
                          maxLength="20"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="">{t("Last_Name")}: </label>
                        <input
                          type="text"
                          placeholder="Doe"
                          value={lastName}
                          disabled={isEdit ? true : false}
                          onChange={(e) => {
                            let lname = e.target.value;
                            lname = lname.replace(/[^A-Za-z]/gi, "");
                            setLastName(lname);
                          }}
                          maxLength="20"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="">{t("Email_Id")}: </label>
                        <input
                          type="text"
                          placeholder="example@mail.com"
                          value={email ? email : ""}
                          disabled={isEdit ? true : isAdmin ? false : true}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="">{t("DOB")}: </label>
                        <input
                          type="date"
                          placeholder="01/01/2022"
                          value={dob}
                          disabled={isEdit ? true : false}
                          onChange={(e) => setDOB(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="">{t("Address")}: </label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="5"
                          value={address}
                          disabled={isEdit ? true : false}
                          onChange={(e) => setAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    {!editBalanceAccess ? (
                      <div className="row mt-5">
                        <div className="col-md-3 m-auto">
                          <div
                            className="btn_primary"
                            onClick={userId ? saveUserProfile : editProfile}
                          >
                            {btnValue === "Edit" ? t("Edit") : t("Save")}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
