import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function MobileNumberInput({ setMobile, setCountryCode }) {
  const { t } = useTranslation();
  const [isMobileNumberError, setIsMobileNumberError] = useState(false);
  const [country, setCountry] = useState("+123");
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
    country: "",
  });

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          // ip: data.ip,
          // countryName: data.country_name,
          // countryCode: data.country_calling_code,
          // city: data.city,
          // timezone: data.timezone,
          country: data.country_calling_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    setCountryCode(state && state.country);
    setCountry(state && state.country);
  }, [state]);

  return (
    <>
      <div className="mobile-number-input-box">
        <label htmlFor="Mobile">{t("Mobile_Number")}: </label>
        <div className="mobile-number-input">
          <input
            type="tel"
            className="country-code"
            value={country}
            autoComplete="new-number"
            onChange={(e) => {
              setCountryCode(e.target.value);
              setCountry(e.target.value);
            }}
            maxLength="5"
          />{" "}
          &nbsp;&nbsp;
          <input
            type="number"
            autoComplete="new-number"
            placeholder="xxx xx xx xx"
            className={`${
              isMobileNumberError ? "number-error" : ""
            } mobile-number`}
            onKeyDown={(e) =>
              exceptThisSymbols.includes(e.key) && e.preventDefault()
            }
            onChange={(e) => {
              setMobile(e.target.value);
              if (
                e.target.value.length < 4 ||
                e.target.value.length === 0 ||
                e.target.value.length > 11
              ) {
                setIsMobileNumberError(true);
              } else {
                setIsMobileNumberError(false);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
