import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer>
        <div className="footer-area">
          <p>{t("Copyright")}</p>
          <NavLink to="/termsConditions">{t("Terms_Conditions")}</NavLink>
          <NavLink to="/FAQ">{t("FAQ")}</NavLink>
          <NavLink to="/about">{t("About_us")}</NavLink>
          <NavLink to="/contactUs">{t("Contact_us")}</NavLink>
          <NavLink to="/privacyPolicy">{t("Privacy_policy")}</NavLink>
        </div>
      </footer>
    </>
  );
};

export default Footer;
