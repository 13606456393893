import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { _getUserTransactions } from "../api/user";
import { useState, useEffect } from "react";

export default function Transactions() {
  const { t } = useTranslation();
  const [recentTransactions, setRecentTransactions] = useState([]);

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  useEffect(() => {
    getRecentTransactions();
  }, []);

  const getRecentTransactions = () => {
    try {
      let requestData = {
        skip: 0,
        limit: 5,
      };
      _getUserTransactions(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setRecentTransactions(res.data.data.result);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="transaction-page custom-box-property">
        <div className="top-heading">
          <h5>{t("Recent_Transactions")}</h5>
        </div>
        <div className="transactions">
          <table className="table-primary_theme table-bordered">
            <thead>
              <tr>
                <td>{t("Date")}</td>
                <td>{t("Type")}</td>
                <td>{t("Amount")}</td>
                <td>{t("Status")}</td>
                <td>{t("Transaction_Id")}</td>
              </tr>
            </thead>
            <tbody>
              {recentTransactions && recentTransactions.length > 0 ? (
                recentTransactions.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td align="left">
                        {moment(item.createdAt).format("ll")}
                      </td>
                      <td align="left">
                        {item.type === "Interest"
                          ? t("Interest")
                          : item.type === "Withdraw"
                          ? t("Withdraw")
                          : item.type === "Deposit"
                          ? t("Deposit")
                          : item.type === "Refund"
                          ? t("Refund")
                          : item.type}
                      </td>
                      <td align="left">
                        {Number(item.amount).toLocaleString(
                          "en-US",
                          formatting_options
                        )}{" "}
                        F CFA
                      </td>
                      <td align="left">
                        {item.statusText === "Pending"
                          ? t("Pending")
                          : item.statusText === "Successful"
                          ? t("Successful")
                          : item.statusText === "Failed"
                          ? t("Failed")
                          : item.statusText}
                      </td>
                      <td align="left">{item.transactionId}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>{t("No_recent_transactions")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="btn-text text-end">
          <NavLink to="/transactionHistory">{t("View_All")}</NavLink>
        </div>
      </div>
    </>
  );
}
