import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import Header1 from "../../header1";
import Sidebar from "../../sidebar";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@material-ui/core";
import CardBox from "../../cardBox";
import {
  _getUserRequests,
  _getAdminDashboardInfo,
  _changeUserRequestStatus,
} from "../../../api/admin";
import { notifyError, notifySuccess } from "../../../utils/notification";
import Footer from "../Footer";
import PaginationRounded from "../Pagination";
import LoaderLayer from "../../LoaderLayer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 600,
  // maxHeight: "80vh",
  // overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  // p: 4,
};

function TransactionStatus() {
  const confirmSave = useConfirm();
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [newRequest, setNewRequest] = useState(0);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [remarks, setRemarks] = useState();
  const [amount, setAmount] = useState();
  const Pending_txt = t("Pending");
  const Approved_txt = t("Approved");
  const Rejected_txt = t("Rejected");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRequests, setTotalRequests] = useState();
  const [approved, setApproved] = useState(0);
  const [activeLoader, setActiveLoader] = useState(true);

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const closeCreditModal = () => setOpenCreditModal(false);

  const openModal = (item) => {
    setOpenCreditModal(true);
    setSelectedTransaction(item);
    setAmount(item.amount);
  };

  const sendMoney = () => {
    if (Number(amount) > Number(selectedTransaction.amount)) {
      notifyError(t("Amount_exceed_requested_amount"));
    } else if (
      Number(amount) > Number(selectedTransaction.userDetail.availableBalance)
    ) {
      notifyError(t("Amount_exceed_current_balance"));
    } else {
      let requestData = {
        id: selectedTransaction._id,
        userId: selectedTransaction.userId,
        amount: amount,
        approved: 1,
        remark: remarks,
      };

      try {
        _changeUserRequestStatus(requestData).then(async (res) => {
          if (res && res.status === 200 && res.data.success) {
            notifySuccess(t("User_request_approved"));
            closeCreditModal();
            getUserRequests();
          } else {
            console.log("Something went wrong");
          }
        });
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const handleReject = (item) => {
    confirmSave({
      title: t("Do_you_want_to_reject_this_request"),
      confirmationText: t("OK"),
      cancellationText: t("Cancel"),
    })
      .then(() => rejectUserRequest(item))
      .catch(() => notifyError(t("Could_not_update_status")));
  };

  const getUserRequests = async () => {
    try {
      let requestData = {
        pageNo: pageNumber ? pageNumber - 1 : 0,
        limit: 20,
        approved: approved,
      };
      await _getUserRequests(requestData).then(async (res) => {
        if (res.status === 200) {
          setTotalRequests(res.data.data.total);
          setTransactions(res.data.data.result);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      setTransactions([]);
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const getAdminDashboardInfo = () => {
    try {
      _getAdminDashboardInfo().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setNewRequest(res.data.data.newlUserRequest);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getUserRequests();
    getAdminDashboardInfo();
  }, [pageNumber, approved]);

  const rejectUserRequest = (item) => {
    let requestData = {
      id: item._id,
      userId: item.userId,
      amount: item.amount,
      approved: 2,
      remark: "User Request Rejected",
    };

    try {
      _changeUserRequestStatus(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          notifySuccess(t("User_request_declined"));
          getUserRequests();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const handleNextPage = () => {
  //   setPageNo(pageNo + 1);
  // };

  // const handlePrevPage = () => {
  //   setPageNo(pageNo > 0 ? pageNo - 1 : 0);
  // };

  const handleDropdownChange = (e) => {
    setApproved(e.target.value);
    setPageNumber(1);
    getUserRequests();
  };

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <div className="card-box">
              <CardBox
                heading={t("New_Requests")}
                cardName="NewRequest"
                value={newRequest}
                icon="fa fa-comments"
              />
            </div>
            <br />
            <section id="userlists">
              <div className="row">
                <div className="col-md-12">
                  <div className="custom-box-property">
                    <h4 className="page-heading">
                      {t("Set_Transaction_Status")}
                    </h4>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="transaction-status_filter">
                          <label htmlFor="transaction">{t("Filter_by")} </label>
                          <select name="" id="" onChange={handleDropdownChange}>
                            <option value="0">{t("Pending")}</option>
                            <option value="1">{t("Approved")}</option>
                            <option value="2">{t("Rejected")}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-status_page">
                      <table className="table-primary_theme table-bordered">
                        <thead>
                          <tr>
                            <td>{t("Transaction_Id")}</td>
                            <td>{t("User_Id")}</td>
                            <td>
                              {t("Date")} & {t("Time")}
                            </td>
                            <td>{t("Type")}</td>
                            <td>{t("Amount")}</td>
                            <td>{t("Status")}</td>
                            <td>{t("Credit_to_user_account")}</td>
                            <td>{t("Reject")}</td>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions && transactions.length > 0 ? (
                            transactions.map((item, index) => {
                              return (
                                <tr key={item._id}>
                                  <td align="left">{item.transactionId}</td>
                                  <td align="left">
                                    {formatMobileNumber(item.userDetail.mobile)}
                                  </td>
                                  <td align="left">
                                    {moment(item.createdAt).format("llll")}
                                  </td>
                                  <td align="left">
                                    {item.type === "Withdraw"
                                      ? t("Withdraw")
                                      : item.type}
                                  </td>
                                  <td align="left">
                                    {Number(item.amount).toLocaleString(
                                      "en-US",
                                      formatting_options
                                    )}{" "}
                                    F CFA
                                  </td>
                                  <td align="left">
                                    {item.approved === 0
                                      ? Pending_txt
                                      : item.approved === 1
                                      ? Approved_txt
                                      : Rejected_txt}
                                  </td>
                                  <td>
                                    <div className="action-btn_group">
                                      <span
                                        className="credit-btn purple-btn"
                                        onClick={() =>
                                          item.approved === 0
                                            ? openModal(item)
                                            : null
                                        }
                                        disabled={
                                          item.approved === 0
                                            ? ""
                                            : item.approved === 1
                                            ? "disabled"
                                            : "disabled"
                                        }
                                      >
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="action-btn_group">
                                      <span
                                        className="cancel-btn green-btn"
                                        disabled={
                                          item.approved === 0
                                            ? ""
                                            : item.approved === 1
                                            ? "disabled"
                                            : "disabled"
                                        }
                                        onClick={() =>
                                          item.approved === 0
                                            ? handleReject(item)
                                            : null
                                        }
                                      >
                                        <i className="fa fa-times green-color"></i>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>{t("No_transactions_found")}!!</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {totalRequests ? (
                    <div className="paginate_theme mt-2">
                      {/* <i className="fa fa-angle-double-left pagination_left" onClick={handlePrevPage}></i> */}
                      &nbsp;&nbsp;
                      <PaginationRounded
                        totalRecord={totalRequests}
                        limit={20}
                        setPageNumber={setPageNumber}
                      />
                      &nbsp;&nbsp;
                      {/* <i className="fa fa-angle-double-right pagination_right" onClick={handleNextPage}></i> */}
                    </div>
                  ) : null}
                  {/* <div className="paginate_theme mt-2">
                    <span
                      className="prev_btn mx-3 custom-cursor_pointer"
                      onClick={handlePrevPage}
                    >
                      {t("PREVIOUS")}
                    </span>
                    <span
                      className="next_btn custom-cursor_pointer"
                      onClick={handleNextPage}
                    >
                      {t("NEXT")}
                    </span>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        open={openCreditModal}
        onClose={closeCreditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-money-modal"
      >
        <Box sx={style} className="border-0 custom-outline_none">
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
          </Typography> */}
          <div className="heading-box">
            <h4 className="modal-heading">{t("Send_Money_To_User")}</h4>
            <div className="close_btn">
              <i
                className="fa fa-times"
                onClick={closeCreditModal}
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="modal-content_box">
            <div className="row pt-3 gy-1 gy-md-2">
              <div className="col-sm-6 ">
                <div className="heading_text">{t("Transaction_Id")}: </div>
                <div className="value_text">
                  {selectedTransaction && selectedTransaction.transactionId}
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="heading_text">{t("User_Id")}:</div>
                <div className="value_text">
                  {selectedTransaction &&
                    formatMobileNumber(selectedTransaction.userDetail.mobile)}
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="heading_text">{t("Requested_Amount")}: </div>
                <div className="value_text">
                  {selectedTransaction &&
                    Number(selectedTransaction.amount).toLocaleString(
                      "en-US",
                      formatting_options
                    )}{" "}
                  F CFA
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="heading_text">{t("Available_Balance")}: </div>
                <div className="value_text">
                  {selectedTransaction &&
                    Number(
                      selectedTransaction.userDetail.availableBalance
                    ).toLocaleString("en-US", formatting_options)}{" "}
                  F CFA
                </div>
              </div>
              <div className="col-sm-12">
                <div className="heading_text">
                  {t("Enter_Amount_to_Send")}:{" "}
                </div>
                <div className="value_text">
                  <div className="input-box">
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 ">
                <div className="heading_text">{t("Remarks")}:</div>
                <div className="value_text">
                  <div className="input-box">
                    <textarea
                      type="text"
                      rows="3"
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-3 justify-content-end">
              <div className="btn_primary btn-modal" onClick={sendMoney}>
                {t("Send_Money")}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TransactionStatus;
