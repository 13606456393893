import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import Sidebar from "../../sidebar";
import Header1 from "../../header1";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@material-ui/core";
import { notifyError, notifySuccess } from "../../../utils/notification";
import {
  _getProviders,
  _addMoneyProvider,
  _updateMoneyProvider,
  _deleteMoneyProvider,
} from "../../../api/providers";
import Footer from "../Footer";
import LoaderLayer from "../../LoaderLayer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 600,
  // minHeight: "60vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  // p: 4,
};

export default function NewMoneyProvider() {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [isActive, setActive] = useState(false);
  const [openEditProviderModal, setOpenEditProviderModal] = useState(false);
  const [openAddProviderModal, setOpenAddProviderModal] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [providerList, setProviderList] = useState([]);
  const [providerId, setProviderId] = useState();
  const [updateProviderName, setUpdateProviderName] = useState();
  const [activeLoader, setActiveLoader] = useState(true);

  const closeAddProviderModal = () => setOpenAddProviderModal(false);
  const closeEditProviderModal = () => setOpenEditProviderModal(false);

  const getProvidersList = () => {
    try {
      _getProviders().then(async (res) => {
        if (res.status === 200) {
          setProviderList(res.data.data);
          setActiveLoader(false);
        } else {
          setProviderList([]);
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const addProvider = () => {
    try {
      let requestData = {
        name: providerName,
        language: "ENGLISH",
      };
      _addMoneyProvider(requestData).then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          notifySuccess(t("Provider_added_successfully"));
          closeAddProviderModal();
          getProvidersList();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const updateProvider = () => {
    try {
      let requestData = {
        name: updateProviderName,
        provider: providerId,
      };
      _updateMoneyProvider(requestData).then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          notifySuccess(t("Provider_updated_successfully"));
          closeEditProviderModal();
          getProvidersList();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const deleteProvider = (_providerId) => {
    try {
      let requestData = {
        id: _providerId,
        status: "0",
      };
      _deleteMoneyProvider(requestData).then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          notifySuccess(t("Provider_inactivated"));
          getProvidersList();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleDelete = (_providerId) => {
    confirm({
      title: t("Delete_provider_confirmation"),
      confirmationText: t("OK"),
      cancellationText: t("Cancel"),
    })
      .then(() => {
        deleteProvider(_providerId);
      })
      .catch(() => notifyError(t("Deletion_cancelled")));
  };

  const handleAddProviderModal = () => {
    setOpenAddProviderModal(true);
  };

  useEffect(() => {
    getProvidersList();
  }, []);

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <div className="new-money-provider-page custom-box-property">
              <h4 className="page-heading">{t("Manage_Money_Providers")}</h4>
              <button
                className="btn_secondary btn_secondary-sm btn-add-provider"
                onClick={handleAddProviderModal}
              >
                {t("Add_New_Provider")}
              </button>
              <table className="table-primary_theme table-bordered">
                <thead>
                  <tr>
                    <td>{t("S_No")}</td>
                    <td>{t("Provider_Name")}</td>
                    <td>{t("Edit")} </td>
                    {/* <td>{t("Delete")}</td> */}
                  </tr>
                </thead>
                <tbody>
                  {providerList.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.name}</td>
                        <td className="action-icon">
                          <span
                            onClick={() => {
                              setProviderId(value._id);
                              setUpdateProviderName(value.name);
                              setOpenEditProviderModal(true);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>{" "}
                          </span>
                        </td>
                        {/* <td className="action-icon">
                          <span
                            onClick={() => {
                              setProviderId(value._id);
                              handleDelete(value._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </span>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="text-end mt-3">
                <NavLink to="/manageMoneyProvider">
                  <button className="btn_primary btn_primary-sm">
                    {t("Instruction_Page")}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        open={openEditProviderModal}
        onClose={closeEditProviderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-money-modal"
      >
        <Box sx={style} className="border-0 custom-outline_none">
          <div className="heading-box">
            <h4 className="modal-heading">{t("Edit_Provider_Name")}</h4>
            <div className="close_btn">
              <i
                className="fa fa-times"
                onClick={closeEditProviderModal}
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="modal-content_box mt-3">
            <div className="input-primary_theme">
              <input
                type="text"
                name=""
                id=""
                autoFocus
                maxLength={30}
                placeholder={t("Enter_Provider_Name")}
                value={updateProviderName}
                onChange={(e) => setUpdateProviderName(e.target.value)}
              />
            </div>
            <div className="mt-2 text-end">
              <button
                className="btn_primary btn-modal"
                onClick={updateProvider}
              >
                {t("Update")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openAddProviderModal}
        onClose={closeAddProviderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-money-modal"
      >
        <Box sx={style} className="border-0 custom-outline_none">
          <div className="heading-box">
            <h4 className="modal-heading">{t("Add_New_Provider")}</h4>
            <div className="close_btn">
              <i
                className="fa fa-times"
                onClick={closeAddProviderModal}
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="modal-content_box mt-3">
            <div className="input-primary_theme">
              <input
                type="text"
                name=""
                id=""
                autoFocus
                maxLength={30}
                placeholder={t("Enter_Provider_Name")}
                onChange={(e) => setProviderName(e.target.value)}
              />
            </div>
            <div className="mt-2 text-end">
              <button className="btn_primary btn-modal" onClick={addProvider}>
                {t("Add")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
