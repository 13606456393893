import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../sidebar";
import Header1 from "../header1";
// import TransactionInstructions from "../transactionInstructions";
import { _sendWithdrawRequest } from "../../api/user";
import { notifyError, notifySuccess } from "../../utils/notification";
import { _getUserBalance, _getUserDetails } from "../../api/user";
import { _getProviders } from "../../api/providers";
import Footer from "./Footer";

function WithdrawMoney() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  // const [showNext, setShowNext] = useState(false);
  const [amount, setAmount] = useState();
  const [userBalance, setUserBalance] = useState();
  const [userDetails, setUserDetails] = useState();
  const [provider, setProvider] = useState("");
  const [values, setValues] = useState([]);
  const exceptThisSymbols = ["e", "E", "+", "-"];

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const sendWithdrawRequest = () => {
    try {
      let requestData = {
        amount: amount,
      };
      if (!amount) {
        notifyError(t("Amount_required"));
      } else {
        _sendWithdrawRequest(requestData).then(async (res) => {
          if (res && res.data.status === 200 && res.data.success) {
            notifySuccess(t("Withdraw_in_process"));
            getUserBalance();
            navigate("/dashboard");
          } else {
            console.log("Something went wrong");
          }
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getUserBalance = () => {
    try {
      _getUserBalance().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setUserBalance(res.data.data);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setUserDetails(res.data.data);
          setProvider(res.data.data.moneyProviderId);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getProvidersList = () => {
    try {
      _getProviders().then(async (res) => {
        if (res.status === 200) {
          setValues(res.data.data);
        } else {
          setValues([]);
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getUserBalance();
    getUserProfile();
    getProvidersList();
  }, []);

  const handleChange = (event) => {
    setProvider(event.target.value);
  };

  const WithdrawRequest = () => {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="money-request_page custom-box-property pb-5">
              <h5 className="my-4 text-center current-balance">
                {t("Current_Balance")}:{" "}
                <span>
                  {userBalance &&
                    Number(userBalance.availableBalance).toLocaleString(
                      "en-US",
                      formatting_options
                    )}{" "}
                  F CFA
                </span>
              </h5>
              <div className="row mt-4">
                <div className="col-md-12">
                  <label>{t("Select_provider")}:</label>
                  <select
                    variant="outlined"
                    required
                    multiline
                    id="provider"
                    value={provider}
                    label={t("Provider")}
                    name="provider"
                    disabled
                    onChange={handleChange}
                  >
                    {values.map((value, index) => {
                      return (
                        <option key={index} value={value._id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="">{t("Amount_Withdraw")} :</label>
                  <input
                    type="number"
                    required
                    name="amount"
                    id="amount"
                    autoFocus
                    value={amount}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
                      setAmount(e.target.value.match(regex)[0]);
                    }}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <label htmlFor="">{t("Recipient_number")}</label>
                  <input
                    type="number"
                    value={userDetails && userDetails.mobile}
                    disabled="true"
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="row g-3 justify-content-center">
                    <div className="col-md-10">
                      <div
                        className="btn_primary"
                        onClick={sendWithdrawRequest}
                      >
                        {t("Send_Withdraw_Request")}
                      </div>
                    </div>
                    {/* <div className="col-md-10">
                      <div
                        className="btn_secondary"
                        // onClick={() => setShowNext(false)}
                      >
                        {t("Back")}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            {/* {!showNext ? (
              <TransactionInstructions
                showNext={showNext}
                setShowNext={setShowNext}
                screen="Withdraw"
              />
            ) : ( */}
            <WithdrawRequest />
            {/* )} */}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default WithdrawMoney;
