import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../sidebar";
import Header1 from "../header1";
import { _getUserTransactions, _getUserDetails } from "../../api/user";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@material-ui/core";
import {
  _getUserTransactionHistoryForAdmin,
  _getAdminTransactions,
} from "../../api/admin";
import { notifyError } from "../../utils/notification";
import Footer from "./Footer";
import PaginationRounded from "./Pagination";
import LoaderLayer from "../LoaderLayer";

function TransactionHistory() {
  const { t } = useTranslation();
  const location = useLocation();
  const userId = location && location.state ? location.state.userId : "";
  const [isActive, setActive] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [openTransactionDetailModal, setOpenTransactionDetailModal] =
    useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [skipPage, setSkipPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState();
  const [activeLoader, setActiveLoader] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    maxWidth: 600,
    // minHeight: "80vh",
    // overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // p: 4,
  };

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  useEffect(() => {
    if (!userId) {
      getLoggedUserProfile();
    }
  }, [skipPage]);

  useEffect(() => {
    if (userId) {
      getUserTransactionHistoryForAdmin();
    } else if (isAdmin && !userId) {
      getTransactionsForAdmin();
    } else if (!isAdmin) {
      getUserTransactions();
    }
  }, [skipPage]);

  useEffect(() => {
    if (pageNumber < 1) {
      setSkipPage(0);
    } else {
      setSkipPage((pageNumber - 1) * 10);
    }
  }, [pageNumber]);

  const getLoggedUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          if (res.data.data.role === "ADMIN") {
            setIsAdmin(true);
            getTransactionsForAdmin();
          } else {
            setIsAdmin(false);
            getUserTransactions();
          }
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getTransactionsForAdmin = () => {
    try {
      let requestData = {
        skip: skipPage,
        limit: 20,
      };
      _getAdminTransactions(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setTotalTransactions(res.data.data.total);
          setTransactions(res.data.data.result);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const getUserTransactions = () => {
    try {
      let requestData = {
        skip: skipPage,
        limit: 20,
      };
      _getUserTransactions(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setTotalTransactions(res.data.data.total);
          setTransactions(res.data.data.result);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getUserTransactionHistoryForAdmin = () => {
    try {
      let requestData = {
        skip: skipPage,
        limit: 20,
        userId: userId,
      };
      _getUserTransactionHistoryForAdmin(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setTotalTransactions(res.data.data.total);
          setTransactions(res.data.data.result);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const openTransactionModal = (item) => {
    setSelectedTransaction(item);
    setOpenTransactionDetailModal(true);
  };

  const closeTransactionDetailModal = () => {
    setOpenTransactionDetailModal(false);
  };

  // const handleNextPage = () => {
  //   setSkipPage(skipPage + 20);
  // };

  // const handlePrevPage = () => {
  //   setSkipPage(skipPage > 0 ? skipPage - 20 : 0);
  // };

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <div className="transaction-page custom-box-property">
              <h4 className="page-heading">{t("Transaction_History")}</h4>
              <div className="transactions ">
                <table className="table-primary_theme table-bordered transaction-history_table">
                  <thead>
                    <tr>
                      {userId ? <td>{t("User_Id")}</td> : null}
                      <td>{t("Date")}</td>
                      <td>{t("Type")}</td>
                      <td>{t("Amount")}</td>
                      <td>{t("Status")}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions && transactions.length > 0 ? (
                      transactions.map((item, index) => {
                        return (
                          <tr
                            key={item._id}
                            onClick={() => openTransactionModal(item)}
                          >
                            {userId ? (
                              <td align="left">
                                {formatMobileNumber(item.userMobile)}
                              </td>
                            ) : null}

                            <td align="left">
                              {moment(item.createdAt).format("ll")}
                            </td>
                            <td align="left">
                              {item.type === "Interest"
                                ? t("Interest")
                                : item.type === "Withdraw"
                                ? t("Withdraw")
                                : item.type === "Deposit"
                                ? t("Deposit")
                                : item.type === "Refund"
                                ? t("Refund")
                                : item.type === "Withdraw(Interest)"
                                ? t("Withdraw_Interest")
                                : item.type === "Deposit(Manual)"
                                ? t("Deposit_Manual")
                                : item.type === "Withdraw(Manual)"
                                ? t("Withdraw_Manual")
                                : item.type ===
                                  "Withdraw(Transfer to mobile account)"
                                ? t("Withdraw_Transfer_account")
                                : item.type}
                            </td>
                            <td align="left">
                              {Number(item.amount).toLocaleString(
                                "en-US",
                                formatting_options
                              )}{" "}
                              F CFA
                            </td>
                            <td align="left">
                              {item.statusText === "Pending"
                                ? t("Pending")
                                : item.statusText === "Successful"
                                ? t("Successful")
                                : item.statusText === "Failed"
                                ? t("Failed")
                                : item.statusText === "Decline"
                                ? t("Decline")
                                : item.statusText}
                            </td>
                            {/* <td className="action-icon">
                              <span
                                onClick={() => openTransactionModal(item)}
                                className="custom-cursor_pointer"
                              >
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </span>
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>{t("No_transactions_found")}!!</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {totalTransactions ? (
                <div className="paginate_theme mt-2">
                  {/* <i className="fa fa-angle-double-left pagination_left" onClick={handlePrevPage}></i> */}
                  &nbsp;&nbsp;
                  <PaginationRounded
                    totalRecord={totalTransactions}
                    limit={20}
                    setPageNumber={setPageNumber}
                  />
                  &nbsp;&nbsp;
                  {/* <i className="fa fa-angle-double-right pagination_right" onClick={handleNextPage}></i> */}
                </div>
              ) : null}
              {/* <div className="paginate_theme mt-2">
                <span
                  className="prev_btn mx-3 custom-cursor_pointer"
                  onClick={handlePrevPage}
                >
                  {t("PREVIOUS")}
                </span>
                <span
                  className="next_btn custom-cursor_pointer"
                  onClick={handleNextPage}
                >
                  {t("NEXT")}
                </span>
              </div> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        open={openTransactionDetailModal}
        onClose={closeTransactionDetailModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-money-modal"
      >
        <Box sx={style} className="border-0 custom-outline_none">
          <div className="heading-box">
            <h4 className="modal-heading">{t("Transaction_details")}</h4>
            <div className="close_btn">
              <i
                className="fa fa-times"
                onClick={closeTransactionDetailModal}
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="modal-content_box">
            <div className="row pt-3 gy-1 gy-md-2">
              <div className="col-sm-6 ">
                <div className="heading_text">{t("Transaction_Id")}:</div>
                <div className="value_text">
                  {selectedTransaction && selectedTransaction.transactionId}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="heading_text">{t("User_Id")}:</div>
                <div className="value_text">
                  {selectedTransaction
                    ? isAdmin
                      ? formatMobileNumber(
                          selectedTransaction.userDetail.mobile
                        ) +
                        " ( " +
                        selectedTransaction.userDetail.firstName +
                        " " +
                        selectedTransaction.userDetail.lastName +
                        " )"
                      : formatMobileNumber(selectedTransaction.userMobile)
                    : null}
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="heading_text">
                  {t("Date")} & {t("Time")}:{" "}
                </div>
                <div className="value_text">
                  {moment(
                    selectedTransaction && selectedTransaction.createdAt
                  ).format("lll")}
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="heading_text">{t("Type")}:</div>
                <div className="value_text">
                  {selectedTransaction
                    ? selectedTransaction.type === "Interest"
                      ? t("Interest")
                      : selectedTransaction.type === "Withdraw"
                      ? t("Withdraw")
                      : selectedTransaction.type === "Deposit"
                      ? t("Deposit")
                      : selectedTransaction.type === "Refund"
                      ? t("Refund")
                      : selectedTransaction.type === "Withdraw(Interest)"
                      ? t("Withdraw_Interest")
                      : selectedTransaction.type
                    : null}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="heading_text">{t("Amount")}:</div>
                <div className="value_text">
                  {selectedTransaction &&
                    Number(selectedTransaction.amount).toLocaleString(
                      "en-US",
                      formatting_options
                    )}{" "}
                  F CFA
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="heading_text">{t("Status")}:</div>
                <div className="value_text">
                  {selectedTransaction
                    ? selectedTransaction.statusText === "Pending"
                      ? t("Pending")
                      : selectedTransaction.statusText === "Successful"
                      ? t("Successful")
                      : selectedTransaction.statusText === "Failed"
                      ? t("Failed")
                      : selectedTransaction.statusText === "Decline"
                      ? t("Decline")
                      : selectedTransaction.statusText
                    : null}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="heading_text">
                  {t("Mobile_Money_Provider")}:{" "}
                </div>
                <div className="value_text">
                  {selectedTransaction &&
                    selectedTransaction.moneyProviderDetail.name}
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="heading_text">{t("Remarks")}:</div>
                <div className="value_text">
                  {selectedTransaction && selectedTransaction.remark
                    ? selectedTransaction.remark
                    : "-"}
                </div>
              </div>
            </div>
            {/* <div className="d-flex mt-3 justify-content-end">
  
                    <div
                      className="btn_primary btn-modal"
                      onClick={closeTransactionDetailModal}
                    >
                      {t("Close")}
                    </div>
            </div> */}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TransactionHistory;
