import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  _getUserDetails,
  _getUserBalance,
  _getCurrentInflationRate,
} from "../api/user";

export default function UserCard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState();
  const [userBalance, setUserBalance] = useState();
  const [inflationRate, setInflationRate] = useState();

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  const navigateToWithdraw = () => {
    navigate("/withdrawMoney");
  };

  const navigateToDeposit = () => {
    navigate("/depositMoney");
  };

  useEffect(() => {
    getUserProfile();
    getUserBalance();
    getCurrentInflationRate();
  }, []);

  const getUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setUserDetails(res.data.data);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getUserBalance = () => {
    try {
      _getUserBalance().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setUserBalance(res.data.data);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getCurrentInflationRate = () => {
    try {
      _getCurrentInflationRate().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setInflationRate(res.data.data.yearlyInterest);
        } else {
          console.log("Something went wrong");
          setInflationRate(0);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-6">
          <div className="user-details custom-box-property">
            <div className="user-details-name">
              <div className="user-icon">
                <i className="fa fa-user-o" aria-hidden="true"></i>
              </div>

              <div className="user-about">
                <h5 className="user-id">
                  {userDetails && formatMobileNumber(userDetails.mobile)}
                </h5>
                <h6 className="user-name">
                  {userDetails && userDetails.firstName}&nbsp;
                  {userDetails && userDetails.lastName}
                </h6>
                <p className="user-earning">
                  {t("You_have_earned")}
                  {userBalance
                    ? userBalance.mboaBalance.toLocaleString(
                        "en-US",
                        formatting_options
                      )
                    : null}{" "}
                  F CFA&nbsp;
                  {t("using_MBOA_Finance")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="custom-box-property current-balance-card ">
            <div className="current-balance">
              <h3 className="balance-value">
                {userBalance
                  ? userBalance.availableBalance.toLocaleString(
                      "en-US",
                      formatting_options
                    )
                  : null}{" "}
                F CFA&nbsp;
              </h3>
              <h5 className="balance-heading">{t("Current_Balance")}</h5>
              <p className="inflation-info">
                {t("Current_Interest_Rate")}: {inflationRate}% {t("per_year")}
              </p>
            </div>
            <div className="row gy-2">
              <div className="col-md-6 col-6" onClick={navigateToWithdraw}>
                <div className="btn_primary">{t("Withdraw")}</div>
              </div>
              <div className="col-md-6 col-6" onClick={navigateToDeposit}>
                <div className="btn_secondary">{t("Deposit")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
