import common from "./common";
import axios from "axios";
import { notifyError } from "../utils/notification";
import { t } from "i18next";

export const _getUserDetails = (data) => {
  var authOptions = {
    method: "GET",
    url: common.userProfile,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getUserBalance = (data) => {
  var authOptions = {
    method: "GET",
    url: common.userBalance,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _getCurrentInflationRate = (data) => {
  var authOptions = {
    method: "GET",
    url: common.getInflationRate,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

export const _sendWithdrawRequest = (data) => {
  var authOptions = {
    method: "POST",
    url: common.sendWithdrawRequest,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "You have insufficient balance to make this transaction. Add more funds to continue."
          ? t("Insufficient_balance")
          : error.response.data.message
      );
    });
};

export const _updateProfile = (data) => {
  var authOptions = {
    method: "PUT",
    url: common.updateProfile,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message ===
          "The provided email is not valid, please check and try again."
          ? t("Invalid_email")
          : error.response.data.message
      );
    });
};

export const _getUserTransactions = (data) => {
  var authOptions = {
    method: "GET",
    url: `${common.getUserTransactions}?skip=${data.skip}&limit=${data.limit}&start&end`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};

export const _getPageContent = (type) => {
  var authOptions = {
    method: "GET",
    url: `${common.getPageContent}?type=${type}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error:", error);
      notifyError(error.response.data.message);
    });
};
