import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import { notifySuccess } from "../../utils/notification";
import {
  _verifyPhone,
  _verify2FAlogin,
  _loginMobile,
  _verifyOtpResetPassword,
  _resendSignupCode,
  _sendMobileOTPonLogin,
  _sendOTPForResetPassword,
} from "../../api/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px",
    margin: "10px auto",
  },
  icon: {
    lineHeight: "28px",
  },
  phone: {
    margin: "10px",
    fontSize: "32px",
  },
}));

function VerifyNumber() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const mobileNo = location.state ? location.state.mobile : "";
  const countryCode = location.state ? location.state.countryCode : "";
  const password =
    location.state && location.state.password ? location.state.password : "";
  const prevScreen = location.state ? location.state.previousPage : "";
  const [otp, setOtp] = useState("");
  const min = 15;
  const sec = 0;
  const [minutes, setMinutes] = useState(min);
  const [seconds, setSeconds] = useState(sec);

  const verifyNumberForSignup = () => {
    let requestData = {
      mobile: mobileNo,
      otp: otp,
      countryCode: countryCode,
    };
    try {
      _verifyPhone(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          sessionStorage.setItem("MobileNo", mobileNo);
          sessionStorage.setItem("Token", res.data.data.token);
          notifySuccess(t("Signup_success"));
          navigate("/dashboard");
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const verifyNumberForLogin = () => {
    let requestData = {
      countryCode: countryCode,
      mobile: mobileNo,
      email: "",
      password: password,
      deviceType: "WEB",
      sectionType: "LOGIN",
      medium: "MOBILE",
      otp: otp,
    };
    try {
      _verify2FAlogin(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          let _requestId = res.data.data.requestId;
          notifySuccess(t("OTP_verified_successfully"));
          try {
            let loginData = {
              requestId: _requestId,
              countryCode: countryCode,
              mobile: mobileNo,
              password: password,
              medium: "MOBILE",
              deviceType: "WEB",
              sectionType: "LOGIN",
            };
            _loginMobile(loginData).then(async (response) => {
              if (
                response &&
                response.status === 200 &&
                response.data.success
              ) {
                sessionStorage.setItem("MobileNo", mobileNo);
                sessionStorage.setItem("Token", response.data.data.token);
                notifySuccess(t("Successfully_logged_in_msg"));
                navigate("/dashboard");
              } else {
                console.log("Something went wrong");
              }
            });
          } catch (error) {
            console.log("Error:", error);
          }
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const verifyNumberForResetPassword = () => {
    let requestData = {
      countryCode: countryCode,
      mobile: mobileNo,
      email: "",
      deviceType: "WEB",
      sectionType: "RESET_PASSWORD",
      medium: "MOBILE",
      otp: otp,
    };
    try {
      _verifyOtpResetPassword(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          let _requestId = res.data.data.requestId;
          notifySuccess(t("OTP_verified_successfully"));
          navigate("/resetPassword", {
            state: {
              requestId: _requestId,
              mobile: mobileNo,
              countryCode: countryCode,
            },
          });
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const resendCodeSignup = () => {
    let requestData = {
      countryCode: countryCode,
      mobile: mobileNo,
    };
    try {
      _resendSignupCode(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          notifySuccess(t("OTP_Sent_For_Login"));
          setMinutes(min);
          setSeconds(sec);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const resendCodeLogin = () => {
    let requestData = {
      countryCode: countryCode,
      mobile: mobileNo,
      email: "",
      password: password,
      deviceType: "WEB",
      sectionType: "LOGIN",
      medium: "MOBILE",
    };
    try {
      _sendMobileOTPonLogin(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          notifySuccess(t("OTP_Sent_For_Login"));
          setMinutes(min);
          setSeconds(sec);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const resendCodeResetPassword = () => {
    let requestData = {
      countryCode: countryCode,
      mobile: mobileNo,
      email: "",
      password: password,
      deviceType: "WEB",
      sectionType: "RESET_PASSWORD",
      medium: "MOBILE",
    };
    try {
      _sendOTPForResetPassword(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          notifySuccess(t("OTP_Sent_For_Login"));
          setMinutes(min);
          setSeconds(sec);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const updateNumber = (e) => {
    e.preventDefault();
    if (prevScreen === "SignUp") {
      navigate("/signup");
    } else if (prevScreen === "Login") {
      navigate("/login");
    } else if (prevScreen === "forgetPassword") {
      navigate("/forgetPassword");
    } else {
      navigate("/resetPassword");
    }
  };

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  // resend otp timer starts here
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);
  // resend otp timer ends here

  return (
    <div className={`${classes.root} mt-4`}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs>
          <Typography variant="h4" gutterBottom>
            {t("Welcome")} !
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h6" gutterBottom>
            {t("Account_secure")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center">
        <Grid item xs>
          <Paper className={classes.paper} square>
            <Grid container direction="column" alignItems="center">
              <Grid item xs>
                <MobileScreenShareIcon
                  fontSize="large"
                  className={classes.phone}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="div" gutterBottom>
                  {t("Enter_code")} {formatMobileNumber(mobileNo)}
                </Typography>
              </Grid>
              <Grid item xs>
                <OtpInput
                  value={otp}
                  shouldAutoFocus={true}
                  onChange={(otp) => {
                    setOtp(otp);
                  }}
                  numInputs={6}
                  inputStyle={{
                    fontSize: "24px",
                    width: "36px",
                    height: "36px",
                    margin: "4px",
                    borderTop: "0px",
                    borderLeft: "0px",
                    borderRight: "0px",
                    outline: "none",
                    borderColor: "#000a46",
                  }}
                  containerStyle={{
                    margin: "10px auto",
                    padding: "10px",
                  }}
                  isInputNum
                />
              </Grid>
              <div
                type="submit"
                className={`${classes.submit} btn_primary w-75`}
                onClick={
                  prevScreen === "SignUp"
                    ? verifyNumberForSignup
                    : prevScreen === "Login"
                    ? verifyNumberForLogin
                    : verifyNumberForResetPassword
                }
              >
                {t("Submit")}
              </div>
              <br />
              <Grid item xs>
                <Typography variant="caption" display="block" gutterBottom>
                  {seconds > 0 || minutes > 0 ? null : (
                    <span
                      // onClick={resendCodeSignup}
                      onClick={
                        prevScreen === "SignUp"
                          ? resendCodeSignup
                          : prevScreen === "Login"
                          ? resendCodeLogin
                          : resendCodeResetPassword
                      }
                      className="custom-cursor_pointer"
                    >
                      {t("Resend_Code")}
                    </span>
                  )}
                  <div className="countdown-text">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        {t("Resend_Code_in")}{" "}
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : // <p>Didn't recieve code?</p>
                    null}
                  </div>
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption" display="block" gutterBottom>
                  <NavLink to="/" onClick={updateNumber}>
                    {t("Wrong_number")}?{/* {t("Update_here")} */}
                  </NavLink>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default VerifyNumber;
