import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header1 from "../../header1";
import Sidebar from "../../sidebar";
import { _getUserList, _blockUnblockUser } from "../../../api/admin";
import { notifyError, notifySuccess } from "../../../utils/notification";
import Footer from "../Footer";
import { _getActiveUserList } from "../../../api/admin";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "react-select-search/style.css";
import PaginationRounded from "../Pagination";
import { _getUserDetailsForAdmin } from "../../../api/admin";
import { ColorRing } from "react-loader-spinner";
import LoaderLayer from "../../LoaderLayer";

function UsersList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [skipPage, setSkipPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [value, setValue] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeLoader, setActiveLoader] = useState(true);
  const [userBalance, setUserBalance] = useState([]);

  useEffect(() => {
    getUserList();
  }, [skipPage]);

  useEffect(() => {
    if (pageNumber < 1) {
      setSkipPage(0);
    } else {
      setSkipPage((pageNumber - 1) * 10);
    }
  }, [pageNumber]);

  const getUserList = () => {
    try {
      let requestData = {
        skip: skipPage,
        limit: 10,
      };
      _getUserList(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setTotalUsers(res.data.data.totalUser);
          setUsersList(res.data.data.user);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getAllUsers = () => {
    try {
      let requestData = {
        skip: 0,
        limit: 0,
      };
      _getUserList(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setAllUsers(res.data.data.user);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [0]);

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  const data = allUsers.map((value) => {
    let item = {};
    item.name =
      (value.firstName ? value.firstName : "") +
      " " +
      value.lastName +
      " - " +
      (value.mobile ? formatMobileNumber(value.mobile) : "");
    item.value = value._id;
    return item;
  });

  async function onAsyncChange(selectedValue) {
    setValue(selectedValue);
    getSelectedUserDetails(selectedValue);
  }

  const getSelectedUserDetails = (userId) => {
    try {
      _getUserDetailsForAdmin(userId).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          let tempArr = [];
          tempArr.push(res.data.data);
          setUsersList(tempArr);
          setTotalUsers(1);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const blockUnblockUser = (selectedUser) => {
    try {
      let requestData = {
        userId: selectedUser._id,
        isBlocked: selectedUser.blockedAt === 0 ? "1" : "0",
      };
      _blockUnblockUser(requestData).then(async (res) => {
        setValue([]);
        if (res && res.data.status === 200 && res.data.success) {
          selectedUser.blockedAt === 0
            ? notifySuccess(t("User_Blocked"))
            : notifySuccess(t("User_Unblocked"));
          getUserList();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleTransactionHistory = (userId) => {
    navigate("/transactionHistory", { state: { userId: userId } });
  };

  const handleEditHistory = (userId) => {
    navigate("/changesHistory", { state: { userId: userId } });
  };

  const handleEditProfile = (userId) => {
    navigate("/profile", { state: { userId: userId } });
  };

  const handleEditMoney = (userId) => {
    navigate("/profile", { state: { userId: userId } });
  };

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <section id="userlists">
              <div className="row">
                <div className="col-md-12">
                  <div className="custom-box-property">
                    <h4 className="page-heading">{t("Users_List")}</h4>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="user-search-bar mb-4">
                          <SelectSearch
                            options={data}
                            value={value}
                            onChange={onAsyncChange}
                            search
                            filterOptions={fuzzySearch}
                            placeholder={t("Search_user")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-list_page">
                      <table className="table-primary_theme table-bordered">
                        <thead>
                          <tr>
                            <td>{t("S_No")} </td>
                            <td>{t("Name")}</td>
                            <td>{t("User_Id")}</td>
                            <td>{t("Mobile_Number")}</td>
                            <td>{t("ACCOUNT_BALANCE")}</td>
                            <td>{t("Transaction_History")}</td>
                            <td>{t("Edit_Profile")}</td>
                            <td>{t("Recent_Edits")}</td>
                            <td>{t("Block_User")}</td>
                          </tr>
                        </thead>
                        <tbody>
                          {usersList.map((val, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{`${val.firstName} ${val.lastName}`}</td>
                                <td>{formatMobileNumber(val.mobile)}</td>
                                <td>{formatMobileNumber(val.mobile)}</td>
                                <td>
                                  <span
                                    className="setUserBalance"
                                    onClick={() => handleEditMoney(val._id)}
                                  >
                                    {Number(
                                      Object.keys(val).includes(
                                        "availableBalance"
                                      )
                                        ? val.availableBalance
                                        : val.balance
                                    ).toLocaleString(
                                      "en-US",
                                      formatting_options
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <div className="action-btn_group">
                                    <span
                                      className="transaction-btn purple-btn"
                                      onClick={() =>
                                        handleTransactionHistory(val._id)
                                      }
                                    >
                                      <i
                                        className="fa fa-exchange"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="action-btn_group">
                                    <span
                                      className="edit_btn green-btn"
                                      onClick={() => handleEditProfile(val._id)}
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                                <td className="action-icon">
                                  <i
                                    className="fa fa-list-alt"
                                    aria-hidden="true"
                                    onClick={() => handleEditHistory(val._id)}
                                  ></i>
                                </td>
                                <td>
                                  <div className="block_btn">
                                    <div className="button-cover">
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          checked={
                                            val.blockedAt === 0 ? false : true
                                          }
                                          onChange={() => {
                                            blockUnblockUser(val);
                                          }}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {totalUsers ? (
                      <div className="paginate_theme mt-2">
                        {/* <i className="fa fa-angle-double-left pagination_left" onClick={handlePrevPage}></i> */}
                        &nbsp;&nbsp;
                        <PaginationRounded
                          totalRecord={totalUsers}
                          limit={10}
                          setPageNumber={setPageNumber}
                        />
                        &nbsp;&nbsp;
                        {/* <i className="fa fa-angle-double-right pagination_right" onClick={handleNextPage}></i> */}
                      </div>
                    ) : null}
                    {/* <div className="paginate_theme mt-2">
                    <span
                      className="prev_btn mx-3 custom-cursor_pointer"
                      onClick={handlePrevPage}
                    >
                      {t("PREVIOUS")}
                    </span>
                    <span
                      className="next_btn custom-cursor_pointer"
                      onClick={handleNextPage}
                    >
                      {t("NEXT")}
                    </span>
                  </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default UsersList;
