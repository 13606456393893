import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import BankImg from "./../../images/Bank.jpg";
import MobileNumberInput from "../mobileNumberInput";
import { notifyError, notifySuccess } from "./../../utils/notification";
import {
  _sendMobileOTPonLogin,
  _checkOtpStatus,
  _loginMobile,
} from "../../api/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LogIn() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState(+234);
  const [password, setPassword] = useState("");
  const [isNumberError, setIsNumberError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const handleVerifyNumber = (e) => {
    e.preventDefault();
    const isValid = isValidPhoneNumber(mobile);

    if (mobile.length === 0 || password.length === 0) {
      notifyError(t("Enter_all_details"));
    } else {
      try {
        let loginData = {
          requestId: "",
          countryCode: countryCode,
          mobile: mobile,
          password: password,
          medium: "MOBILE",
          deviceType: "WEB",
          sectionType: "LOGIN",
        };
        _loginMobile(loginData).then(async (response) => {
          if (
            response &&
            response.status === 200 &&
            response.data.success
          ) {
            sessionStorage.setItem("MobileNo", mobile);
            sessionStorage.setItem("Token", response.data.data.token);
            notifySuccess(t("Successfully_logged_in_msg"));
            navigate("/dashboard");
          } else {
            console.log("Something went wrong");
          }
        });
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="login-page py-3">
          <div className="login-page_box">
            <div className="row gx-0 h-100">
              <div className="col-md-6">
                <div className="image-box d-none d-md-block">
                  <img src={BankImg} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="right-box">
                  <div>
                    <div className="text-center">
                      <span className="icon-box">
                        <LockOutlinedIcon />
                      </span>

                      <h3>{t("Login")}</h3>
                    </div>
                    <form
                      className={classes.form}
                      noValidate
                      autoComplete="off"
                    >
                      <MobileNumberInput
                        setMobile={setMobile}
                        setCountryCode={setCountryCode}
                      />
                      <label htmlFor="password" className="mt-4">
                        {t("Enter_Password")}:{" "}
                      </label>
                      <input
                        required
                        error={isPasswordError}
                        placeholder="********"
                        name="password"
                        label={t("Enter_Password")}
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (e.target.value.length === 0) {
                            setIsPasswordError(true);
                          } else {
                            setIsPasswordError(false);
                          }
                        }}
                      />
                      {/* <div className="final-checkbox">
                      <FormControlLabel
                        className="m-0"
                        control={
                          <Checkbox
                            value="remember"
                            className="p-0"
                            color="primary"
                          />
                        }
                      />
                      <div className="final-text">Remember me</div>
                    </div> */}
                      <br />
                      <br />
                      <div className="text-center">
                        <button
                          type="submit"
                          className={`${classes.submit} btn_primary w-50 m-auto`}
                          onClick={(e) => handleVerifyNumber(e)}
                        >
                          {t("Login")}
                        </button>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 text-center">
                          <div className="forget-password">
                            <NavLink to="/forgetPassword" variant="body2">
                              {t("Forgot_password")}?
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <div className="account-confirm-box">
                            {t("No_account")}?
                            <NavLink to="/signup" variant="body2">
                              {t("No_account_signup")}
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LogIn;
