import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import WithdrawImg from "../assets/images/bank-withdraw.png";
import DepositImg from "../assets/images/marketing.png";
import { _getProviders, _getProviderInstructions } from "../api/providers";
import { _getUserDetails } from "../api/user";

const TransactionInstructions = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [provider, setProvider] = useState("");
  const [values, setValues] = useState([]);
  const [instructions, setInstructions] = useState();
  // const withdrawInstructions = t("Withdraw_instructions");
  // const withdraw_heading = t("Process_Withdrawal_Instructions");
  const deposit_heading = t("Process_Deposit_Instructions");
  // const next_txt = t("Next");
  const done_txt = t("Done");

  const handleBtnClick = () => {
    // if (props.screen === "Withdraw") {
    //   props.setShowNext(true);
    // } else {
    navigate("/dashboard");
    // }
  };

  function handleChange(event) {
    setProvider(event.target.value);
  }

  const getProvidersList = () => {
    try {
      _getProviders().then(async (res) => {
        if (res.status === 200) {
          setValues(res.data.data);
          // setProvider(res.data.data[0]._id);
        } else {
          setValues([]);
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setProvider(res.data.data.moneyProviderId);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getProviderInstructions = () => {
    try {
      if (provider) {
        _getProviderInstructions(provider).then(async (res) => {
          if (res.status === 200) {
            const instruction =
              res.data.data.instructionList.length > 0
                ? props.language === "en"
                  ? res.data.data.instructionList[0].instruction
                  : res.data.data.instructionList[1].instruction
                : null;
            // const formattedInstruction = instruction
            //   ? instruction.replace(/(<([^>]+)>)/gi, "")
            //   : "";
            setInstructions(instruction);
          } else {
            console.log("Something went wrong");
          }
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getUserProfile();
    getProvidersList();
    getProviderInstructions();
  }, []);

  useEffect(() => {
    getProviderInstructions();
  }, [provider, props.language]);

  return (
    <>
      <div className="transaction-instruction_page custom-box-property">
        <div className="row gy-4">
          <div className="col-md-5 d-flex">
            <div className="img-box">
              <img src={DepositImg} alt="" />
            </div>
          </div>
          <div className="col-md-7">
            <h4 className="page-heading">{deposit_heading}</h4>
            {/* <h4 className="text-center my-3">{deposit_heading}</h4> */}
            {props.screen === "Deposit" ? (
              <div className="col-md-6">
                <label>{t("Select_provider")}:</label>
                <select
                  variant="outlined"
                  required
                  multiline
                  id="provider"
                  value={provider}
                  label={t("Provider")}
                  name="provider"
                  onChange={handleChange}
                  className="provider_center_align"
                >
                  {values.map((value, index) => {
                    return (
                      <option key={index} value={value._id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            <div
              className="instructions-list"
              dangerouslySetInnerHTML={{
                __html: instructions ? instructions : "",
              }}
            ></div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div
                  className="btn_primary text-center mt-3"
                  onClick={handleBtnClick}
                >
                  {done_txt}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionInstructions;
