import React, { useState } from "react";
import { notifyError, notifySuccess } from "../../utils/notification";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { _resetPassword } from "../../api/auth";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const requestedId = location.state ? location.state.requestId : "";
  const mobile = location.state ? location.state.mobile : "";
  const countryCode = location.state ? location.state.countryCode : "";
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const updatePassword = (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      notifyError(t("Enter_all_details"));
    } else {
      if (newPassword === confirmPassword) {
        try {
          let requestData = {
            countryCode: countryCode,
            mobile: mobile,
            email: "",
            password: newPassword,
            sectionType: "RESET_PASSWORD",
            medium: "MOBILE",
            requestId: requestedId,
          };
          _resetPassword(requestData).then(async (res) => {
            if (res && res.status === 200 && res.data.success) {
              notifySuccess(t("Password_reset_successfully"));
              navigate("/login");
            } else {
              console.log("Something went wrong");
            }
          });
        } catch (error) {
          console.log("Error:", error);
        }
      } else {
        notifyError(t("Password_does_not_match"));
      }
    }
  };

  return (
    <div className="container">
      <div className="row custom-item-center">
        <div className="col-md-6 m-auto">
          <div className="change-password_page  custom-box-property">
            <h4 className="page-heading">{t("Change_Password")}</h4>
            <form action="" noValidate>
              <div className="row g-3">
                <div className="col-md-12">
                  <label htmlFor="">{t("New_Password")}</label>
                  <input
                    required
                    name="newPassword"
                    type="Password"
                    id="newPassword"
                    autoComplete="new-password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="">{t("Confirm_Password")}</label>
                  <input
                    required
                    name="confirmPassword"
                    label={t("Confirm_Password")}
                    type="Password"
                    id="confirmPassword"
                    autoComplete="confirm-password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6"></div>
                <div className="col-6">
                  <div
                    className="btn_primary"
                    onClick={(e) => updatePassword(e)}
                  >
                    {t("Update")}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
