import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header1 from "../../header1";
import Sidebar from "../../sidebar";
import NotificationCard from "../../NotificationCard";
import {
  _getAdminNotification,
  _markNotificationAsRead,
  _markAllAsRead,
} from "../../../api/admin";
import { notifyError, notifySuccess } from "../../../utils/notification";
import Footer from "../Footer";
import { Button } from "reactstrap";
import PaginationRounded from "../Pagination";
import LoaderLayer from "../../LoaderLayer";

function Notifications() {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [notification, setNotification] = useState([]);
  // const [pageNo, setPageNo] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState();
  const [notificationUpdated, setNotificationUpdated] = useState(false);
  const [disableBtns, setDisableBtns] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalNotifications, setTotalNotifications] = useState();
  const [activeLoader, setActiveLoader] = useState(true);

  useEffect(() => {
    getAdminNotification();
  }, [pageNumber]);

  // useEffect(() => {
  //   if (pageNumber < 1) {
  //     setPageNo(0);
  //   } else {
  //     setPageNo(pageNumber - 1);
  //   }
  // }, [pageNumber]);

  // const handleNextPage = () => {
  //   setPageNo(pageNo + 1);
  // };

  // const handlePrevPage = () => {
  //   setPageNo(pageNo > 0 ? pageNo - 1 : 0);
  // };

  const getAdminNotification = () => {
    try {
      let requestData = {
        pageNo: pageNumber ? pageNumber - 1 : 0,
        limit: 10,
      };
      _getAdminNotification(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          if (res.data.data.notification.length === 0) {
            setPageNumber(1);
          }
          setTotalNotifications(res.data.data.totalNotification);
          setNotification(res.data.data.notification);
          setDisableBtns(res.data.data.notification.length > 0 ? false : true);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const markNotificationAsRead = () => {
    try {
      let requestData = {
        id: selectedNotification,
      };
      _markNotificationAsRead(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setNotificationUpdated(!notificationUpdated);
          notifySuccess(t("Notification_read_successfully"));
          getAdminNotification();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const markAllAsRead = () => {
    try {
      let requestData = {
        id: selectedNotification,
      };
      _markAllAsRead(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setNotificationUpdated(!notificationUpdated);
          notifySuccess(t("Notification_read_successfully"));
          getAdminNotification();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (selectedNotification) {
      markNotificationAsRead();
    }
  }, [selectedNotification]);

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1
            setActive={setActive}
            isActive={isActive}
            notificationUpdated={notificationUpdated}
          />
          <div className="main-content">
            <div className="custom-box-property">
              <div className="row pb-2 align-items-center">
                <div className="col-md-8">
                  <h4 className="page-heading pb-0">{t("Notifications")}</h4>
                </div>
                <div className="col-md-4">
                  <div className="notification_all_read text-end">
                    <Button
                      color="info"
                      size="sm"
                      onClick={markAllAsRead}
                      disabled={disableBtns}
                    >
                      {t("Mark_all_read")} &nbsp; &nbsp;
                      <i
                        className="fa fa-envelope-open-o"
                        aria-hidden="true"
                      ></i>
                    </Button>{" "}
                  </div>
                </div>
              </div>
              {notification && notification.length > 0 ? (
                notification.map((value, index) => {
                  return (
                    <NotificationCard
                      type="success"
                      notification={value}
                      key={index}
                      setSelectedNotification={setSelectedNotification}
                    />
                  );
                })
              ) : (
                <div>{t("No_new_notifications")}</div>
              )}
              {/* <NotificationCard type={"pending"} message={"Hello"} />
              <NotificationCard type={"error"} message={"Hello"} />
              <NotificationCard type={"success"} message={"Hello"} /> */}
              {totalNotifications ? (
                <div className="paginate_theme mt-2">
                  {/* <i className="fa fa-angle-double-left pagination_left" onClick={handlePrevPage}></i> */}
                  &nbsp;&nbsp;
                  <PaginationRounded
                    totalRecord={totalNotifications}
                    limit={10}
                    setPageNumber={setPageNumber}
                  />
                  &nbsp;&nbsp;
                  {/* <i className="fa fa-angle-double-right pagination_right" onClick={handleNextPage}></i> */}
                </div>
              ) : null}
              {/* <div className="paginate_theme mt-2">
              <span
                className="prev_btn mx-3 custom-cursor_pointer"
                onClick={handlePrevPage}
              >
                {t("PREVIOUS")}
              </span>
              <span
              className="next_btn custom-cursor_pointer"
              onClick={handleNextPage}
              >
              {t("NEXT")}
              </span>
            </div> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Notifications;
