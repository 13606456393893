import React from "react";
import { ColorRing } from "react-loader-spinner";

export default function LoaderLayer(props) {
  return (
    <>
      {props.activeLoaderValue ? (
        <div className={`overlayer ${props.activeLoaderValue ? "" : "d-none"}`}>
          <div className="overlayer2">
            <ColorRing
              visible={props.activeLoaderValue}
              height="120"
              width="120"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["white", "white", "white", "white", "white"]}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
