import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { useTranslation } from "react-i18next";
import { _getGraphData } from "../../api/admin";

const Charts = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState();
  const [category, setCategory] = useState("total-interest");
  const [timePeriod, setTimePeriod] = useState("daily");

  const handleCategoryDropdown = (e) => {
    setCategory(e.target.value);
  };

  const handleTimeDropdown = (e) => {
    setTimePeriod(e.target.value);
  };

  useEffect(() => {
    getChartData();
  }, [category, timePeriod]);

  const getChartData = () => {
    try {
      _getGraphData(category, timePeriod).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setChartData(res.data.data.data);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="row mb-4 g-2">
        {/* <label htmlFor="transaction">{t("Filter_by")} </label> */}
        <div className="col-md-6">
          <div className="transaction-status_filter">
            <select
              id="category"
              onChange={handleCategoryDropdown}
              value={category}
            >
              <option value="total-interest">{t("Total_Interest")}</option>
              <option value="total-user">{t("Total_Users")}</option>
              <option value="total-amount">
                {t("Total_Amount_Deposited")}
              </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="transaction-status_filter">
            {/* <label htmlFor="transaction">{t("Filter_by")} </label> */}
            <select
              id="timePeriod"
              onChange={handleTimeDropdown}
              value={timePeriod}
            >
              <option value="daily">{t("Daily")}</option>
              <option value="weekly">{t("Weekly")}</option>
              <option value="yearly">{t("Yearly")}</option>
            </select>
          </div>
        </div>
      </div>
      <Paper>
        {chartData ? (
          <Chart data={chartData} height={400}>
            <ArgumentAxis />
            <ValueAxis max={7} />
            <BarSeries
              valueField="amount"
              argumentField="date"
              color="#6d72f6"
              barWidth={
                timePeriod === "daily"
                  ? "0.5"
                  : timePeriod === "weekly"
                  ? "0.3"
                  : "0.1"
              }
            />
            {/* <Title text="Charts" /> */}
            <Animation />
          </Chart>
        ) : null}
      </Paper>
    </>
  );
};

export default Charts;
