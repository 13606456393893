import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import heroBg from "../../assets/images/shape-bg-home-hero.svg";
import bannerImg from "../../assets/images/Bank.jpg";
import Header1 from "../header1";
import Footer from "./Footer";

export default function Welcome() {
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("Token")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      <div className="landing-page">
        <div className="container">
          <Header1 screen="welcomeScreen" />
        </div>
        <section className="landing-banner">
          <div className="container">
            <div className="banner-child">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="banner-left text-center text-md-start">
                    <div>
                      <h1>{t("Welcome-Heading")}</h1>
                      <p>{t("Welcome-Desc")}</p>
                      {!isLoggedIn ? (
                        <div className="row g-3 justify-content-center justify-content-md-start">
                          <div className="col-md-5">
                            <NavLink to="login">
                              <button className="btn_primary w-100">
                                {t("Login-text")}
                              </button>
                            </NavLink>
                          </div>
                          <div className="col-md-5">
                            <NavLink to="/signup">
                              <button className="btn_secondary w-100">
                                {t("Signup-text")}
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="banner-right">
                    <img src={bannerImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-bg">
            <img src={heroBg} alt="" />
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
