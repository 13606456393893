import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Header1 from "../header1";
import Sidebar from "../sidebar";
import { Box, Typography, Modal } from "@mui/material";
import { _getUserHistory } from "../../api/admin";
import PaginationRounded from "./Pagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: "90vw",
  // minHeight: "80vh",
  maxHeight: "80vh",
  // overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
};

export default function ChangesHistory() {
  const { t } = useTranslation();
  const location = useLocation();
  const userId = location.state ? location.state.userId : "";
  const [isActive, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [userHistory, setUserHistory] = useState();
  const [selectedChange, setSelectedChange] = useState();
  const [skipPage, setSkipPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalChanges, setTotalChanges] = useState();

  useEffect(() => {
    getUserHistory();
  }, [skipPage]);

  useEffect(() => {
    if (pageNumber < 1) {
      setSkipPage(0);
    } else {
      setSkipPage((pageNumber - 1) * 10);
    }
  }, [pageNumber]);

  const getUserHistory = () => {
    try {
      let requestData = {
        skip: skipPage,
        limit: 10,
      };
      _getUserHistory(userId, requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setTotalChanges(res.data.data.totalRecord);
          setUserHistory(res.data.data.result);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const handleNextPage = () => {
  //   setSkipPage(skipPage + 10);
  // };

  // const handlePrevPage = () => {
  //   setSkipPage(skipPage > 0 ? skipPage - 10 : 0);
  // };

  const openChangesModal = (item) => {
    setSelectedChange(item);
    setOpen(true);
  };

  const closeChangeDetailModal = () => {
    setOpen(false);
  };

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  return (
    <>
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <div className="changes-history-page custom-box-property">
              <div className="row">
                <div className="text-center">
                  <h4 className="page-heading">{t("Previous_Changes")}</h4>
                </div>
                <div className="changes-history">
                  <table className="table-primary_theme">
                    <thead>
                      <tr>
                        <td>{t("S_No")}</td>
                        <td>{t("User_Id")}</td>
                        <td>{t("Date")}</td>
                        <td>{t("Updated_By")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {userHistory && userHistory.length > 0 ? (
                        userHistory.map((item, index) => {
                          return (
                            <tr
                              key={item._id}
                              onClick={() => openChangesModal(item)}
                            >
                              <td align="left">{index + 1}</td>
                              <td align="left">
                                {formatMobileNumber(item.mobile)}
                              </td>
                              <td align="left">
                                {moment(item.capturedAt).format("ll")}
                              </td>
                              <td align="left">{t("ADMIN")}</td>
                              {/* <td className="action-icon">
                                <span
                                  onClick={() => openChangesModal(item)}
                                  className="custom-cursor_pointer"
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>{t("No_record_found")}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {totalChanges ? (
                  <div className="paginate_theme mt-2">
                    {/* <i className="fa fa-angle-double-left pagination_left" onClick={handlePrevPage}></i> */}
                    &nbsp;&nbsp;
                    <PaginationRounded
                      totalRecord={totalChanges}
                      limit={10}
                      setPageNumber={setPageNumber}
                    />
                    &nbsp;&nbsp;
                    {/* <i className="fa fa-angle-double-right pagination_right" onClick={handleNextPage}></i> */}
                  </div>
                ) : null}
                {/* <div className="paginate_theme mt-2">
                  <span
                    className="prev_btn mx-3 custom-cursor_pointer"
                    onClick={handlePrevPage}
                  >
                    {t("PREVIOUS")}
                  </span>
                  <span
                    className="next_btn custom-cursor_pointer"
                    onClick={handleNextPage}
                  >
                    {t("NEXT")}
                  </span>
                </div> */}
              </div>
            </div>
            <Modal
              open={open}
              onClose={closeChangeDetailModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="send-money-modal"
            >
              <Box sx={style} className="border-0 custom-outline_none">
                <div className="heading-box">
                  <div className="modal-heading">
                    {t("Changed_By")}: {t("ADMIN")}
                    <div className="modal-subheading">
                      {t("On")}{" "}
                      {moment(
                        selectedChange && selectedChange.capturedAt
                      ).format("lll")}
                    </div>
                  </div>
                  <div className="close_btn">
                    <i
                      className="fa fa-times"
                      onClick={closeChangeDetailModal}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="modal-content_box pr_5">
                  <div className="pt-3 gy-2 modal_overflow">
                    <table className="table-primary_theme modal-table">
                      <thead>
                        <tr>
                          <td>{t("Fields")}</td>
                          <td>{t("Old_Value")}</td>
                          <td>{t("New_Value")}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedChange && selectedChange.firstName ? (
                          <>
                            <tr>
                              <td>{t("First_Name")}: </td>
                              <td>
                                {selectedChange &&
                                  selectedChange.firstName.oldValue}
                              </td>
                              <td>
                                {selectedChange &&
                                  selectedChange.firstName.newValue}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        {selectedChange && selectedChange.lastName ? (
                          <>
                            <tr>
                              <td>{t("Last_Name")}: </td>
                              <td>
                                {selectedChange &&
                                  selectedChange.lastName.oldValue}
                              </td>
                              <td>
                                {selectedChange &&
                                  selectedChange.lastName.newValue}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        {selectedChange && selectedChange.email ? (
                          <>
                            <tr>
                              <td>{t("Email_Id")}: </td>
                              <td>
                                {selectedChange &&
                                  selectedChange.email.oldValue}
                              </td>
                              <td>
                                {selectedChange &&
                                  selectedChange.email.newValue}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        {selectedChange && selectedChange.dob ? (
                          <>
                            <tr>
                              <td>{t("DOB")}:</td>
                              <td>
                                {selectedChange &&
                                  moment(selectedChange.dob.oldValue).format(
                                    "YYYY-MM-DD"
                                  )}
                              </td>
                              <td>
                                {selectedChange &&
                                  moment(selectedChange.dob.newValue).format(
                                    "YYYY-MM-DD"
                                  )}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        {selectedChange && selectedChange.address ? (
                          <>
                            <tr>
                              <td>{t("Address")}: </td>
                              <td className="white-space_break-words">
                                {selectedChange &&
                                  selectedChange.address.oldValue}
                              </td>
                              <td className="white-space_break-words">
                                {selectedChange &&
                                  selectedChange.address.newValue}
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}
