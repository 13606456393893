import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header1 from "../../header1";
import Sidebar from "../../sidebar";
import { _depositBalance, _getActiveUserList } from "../../../api/admin";
import { notifySuccess, notifyError } from "../../../utils/notification";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "react-select-search/style.css";
import Footer from "../Footer";
import LoaderLayer from "../../LoaderLayer";

export default function DepositToUser() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [userId, setUserId] = useState();
  const [sendAmount, setSendAmount] = useState();
  const [sendRemark, setSendRemark] = useState("");
  const [value, setValue] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [activeLoader, setActiveLoader] = useState(true);

  const exceptThisSymbols = ["e", "E", "+", "-"];

  useEffect(() => {
    getActiveUserList();
  }, []);

  const formatMobileNumber = (number) => {
    var rem = number.length - 6;
    var regex = new RegExp("(\\d{" + rem + "})(\\d{2})(\\d{2})(\\d{2})");
    let res = number.replace(regex, "$1 $2 $3 $4");
    return res;
  };

  const data = usersList.map((value) => {
    let item = {};
    item.name =
      (value.firstName ? value.firstName : "") +
      " " +
      value.lastName +
      " - " +
      (value.mobile ? formatMobileNumber(value.mobile) : "");
    item.value = value._id;
    return item;
  });

  const getActiveUserList = () => {
    try {
      _getActiveUserList().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setUsersList(res.data.data);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const depositBalanceToUser = () => {
    try {
      let requestData = {
        userId: userId,
        amount: sendAmount,
        remark: sendRemark,
      };
      if (!sendAmount || !userId) {
        notifyError(t("Amount_required"));
      } else {
        _depositBalance(requestData).then(async (res) => {
          if (res && res.data.status === 200 && res.data.success) {
            navigate("/dashboard");
            notifySuccess(t("User_request_successfully_approved"));
          } else {
            console.log("Something went wrong");
          }
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  async function onAsyncChange(selectedValue) {
    setValue(selectedValue);
    setUserId(selectedValue);
  }

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1 setActive={setActive} isActive={isActive} />
          <div className="main-content">
            <div className="deposit-to-user-page custom-box-property">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <h4 className="page-heading">
                    {t("Deposite_Money_User_Account")}
                  </h4>
                  <form action="">
                    <div className="row gy-3">
                      <div className="col-md-12">
                        <label htmlFor="">{t("Select_User")}:</label>
                        <div className="user-search-bar">
                          <SelectSearch
                            options={data}
                            value={value}
                            onChange={onAsyncChange}
                            search
                            filterOptions={fuzzySearch}
                            placeholder={t("Enter_User_Name")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="">{t("Enter_Amount")}:</label>
                        <input
                          type="number"
                          placeholder={t("Enter_Amount")}
                          value={sendAmount}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => {
                            // exceptThisSymbols.includes(e.key) &&
                            e.preventDefault();
                            // const regex = /([0-9, "e", "E", "+", "-"]*[\.|\,]{0,1}[0-9]{0,2})/s;
                            const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
                            setSendAmount(e.target.value.match(regex)[0]);
                          }}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="">{t("Remarks")}:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="4"
                          placeholder={t("Add_remarks_here")}
                          onChange={(e) => setSendRemark(e.target.value)}
                        />
                      </div>
                      <div className="col-5 text-center m-auto mt-3">
                        <button
                          className="btn_primary "
                          type="button"
                          onClick={depositBalanceToUser}
                        >
                          {t("Deposite")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
