import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import cookies from "js-cookie";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import Sidebar from "../../sidebar";
import Header1 from "../../header1";
import {
  _getProviders,
  _getProviderInstructions,
  _updateInstructions,
} from "../../../api/providers";
import { notifySuccess, notifyError } from "../../../utils/notification";
import Footer from "../Footer";
import LoaderLayer from "../../LoaderLayer";

export default function ManageMoneyProvider() {
  const confirmSave = useConfirm();
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [provider, setProvider] = useState("");
  const [values, setValues] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [language, setLanguage] = useState(cookies.get("i18next") || "fr");
  const quillData = useRef();
  const [activeLoader, setActiveLoader] = useState(true);

  const handleSave = () => {
    confirmSave({
      title: t("Do_you_really_want_to_save_instructions"),
      confirmationText: t("OK"),
      cancellationText: t("Cancel"),
    })
      .then(() => updateInstructions())
      .catch(() => notifyError(t("Could_not_update_instructions")));
  };

  function handleChange(event) {
    setProvider(event.target.value);
  }

  const getProvidersList = () => {
    try {
      _getProviders().then(async (res) => {
        if (res.status === 200) {
          setValues(res.data.data);
          setProvider(res.data.data[0]._id);
        } else {
          setValues([]);
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getProviderInstructions = () => {
    try {
      if (provider) {
        _getProviderInstructions(provider).then(async (res) => {
          if (res.status === 200) {
            setInstructions(
              res.data.data.instructionList.length > 0
                ? language === "en"
                  ? res.data.data.instructionList[0].instruction
                  : res.data.data.instructionList[1].instruction
                : ""
            );
            setActiveLoader(false);
          } else {
            console.log("Something went wrong");
          }
        });
      }
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  useEffect(() => {
    getProvidersList();
    getProviderInstructions();
  }, []);

  useEffect(() => {
    getProviderInstructions();
  }, [provider, language]);

  const updateInstructions = () => {
    setIsEdit(!isEdit);
    try {
      let requestData = {
        instruction: quillData.current.state.value,
        provider: provider,
        language: language === "en" ? "ENGLISH" : "FRENCH",
      };
      _updateInstructions(requestData).then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          notifySuccess(t("Instruction_updated_successfully"));
          getProviderInstructions();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const editInstruction = () => {
    setIsEdit(!isEdit);
  };

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1
            setActive={setActive}
            isActive={isActive}
            setLanguage={setLanguage}
          />
          <div className="main-content">
            <div className="manage-money-provider-page custom-box-property">
              <div className="">
                <h4 className="page-heading">
                  {t("Manage_Mobile_Money_Provider_Instructions")}
                </h4>
              </div>
              <div className="row mb-3 gy-4">
                <div className="col-md-5">
                  <label htmlFor="">{t("Select_provider")}: </label>
                  <select
                    variant="outlined"
                    required
                    multiline
                    id="provider"
                    value={provider}
                    label="Provider"
                    name="provider"
                    onChange={handleChange}
                  >
                    {values.map((value, index) => {
                      return (
                        <option key={index} value={value._id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-md-end gy-3">
                    {isEdit ? null : (
                      <div className="custom-mr-10">
                        <label htmlFor="" className="d-none d-md-block">
                          {" "}
                          &nbsp;
                        </label>
                        <button
                          className="btn_primary btn_primary-sm"
                          onClick={editInstruction}
                        >
                          {t("Edit_Instructions")}
                        </button>
                      </div>
                    )}
                    <div>
                      <label htmlFor="" className="d-none d-md-block">
                        {" "}
                        &nbsp;
                      </label>
                      <NavLink to="/newMoneyProvider">
                        <button className="btn_primary btn_primary-sm">
                          {t("Manage_Providers")}
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ReactQuill
                  theme="snow"
                  placeholder={t("Enter_provider_instructions")}
                  value={instructions ?? ""}
                  ref={quillData}
                  readOnly={isEdit ? false : true}
                />
              </div>
              {isEdit ? (
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn_primary btn_primary-sm"
                    onClick={handleSave}
                  >
                    {t("Save")}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
