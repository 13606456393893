import React, { useState } from "react";
import Sidebar from "../sidebar";
import Header1 from "../header1";
import cookies from "js-cookie";
import TransactionInstructions from "../transactionInstructions";
import Dashboard from "../dashboard/dashboard";
import Footer from "./Footer";

function DepositMoney() {
  const [showNext, setShowNext] = useState(false);
  const [isActive, setActive] = useState(false);
  const [language, setLanguage] = useState(cookies.get("i18next") || "fr");

  return (
    <>
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1
            setActive={setActive}
            isActive={isActive}
            setLanguage={setLanguage}
          />
          <div className="main-content">
            {!showNext ? (
              <TransactionInstructions
                showNext={showNext}
                setShowNext={setShowNext}
                screen="Deposit"
                language={language}
              />
            ) : (
              <Dashboard />
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default DepositMoney;
