import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import i18next from "i18next";
import cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { _getAdminNotification } from "../api/admin";
import { _getUserDetails } from "../api/user";
import userIcon from "../assets/icons/user.png";
import { notifyInfo } from "../utils/notification";
import { _validateToken } from "../api/auth";

const GlobeIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
  </svg>
);

const languages = [
  {
    code: "fr",
    name: "Français",
  },
  {
    code: "en",
    name: "English",
  },
];

export default function Header1(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(cookies.get("i18next") || "fr");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [notificationNumber, setNotificationNumber] = useState(0);
  const [showSettingMenus, setShowSettingMenus] = useState(false);
  const [isAuthentic, setIsAuthentic] = useState(true);

  const showSetting = (event) => {
    setShowSettingMenus(!showSettingMenus);
    event.stopPropagation();
  };

  const showDropMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLoginScreen = () => {
    navigate("/login");
  };
  const handleProfilePage = () => {
    navigate();
  };

  const showSideNav = () => {
    props.setActive(!props.isActive);
  };

  const handleNotifications = () => {
    navigate("/notifications");
  };

  useEffect(() => {
    if (sessionStorage.getItem("Token")) {
      isUserAuthenticated();
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("Token")) {
      isUserAuthenticated();
    } else {
      setIsLoggedIn(false);
    }
  }, [props.notificationUpdated]);

  const isUserAuthenticated = () => {
    if (sessionStorage.getItem("Token")) {
      try {
        _validateToken().then(async (res) => {
          if (res && res.data.status === 200 && res.data.success) {
            setIsLoggedIn(true);
            getUserProfile();
            setIsAuthentic(true);
          } else {
            console.log("Something went wrong");
            setIsAuthentic(false);
          }
        });
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      setIsAuthentic(false);
    }
  };

  const getUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setUserDetails(res.data.data);
          if (res.data.data.role === "ADMIN") {
            getAdminNotification();
            setIsAdmin(true);
          }
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getAdminNotification = () => {
    try {
      let requestData = {
        pageNo: 0,
        limit: 10,
      };
      _getAdminNotification(requestData).then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setNotificationNumber(res.data.data);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleLogOut = () => {
    sessionStorage.removeItem("Token");
    sessionStorage.removeItem("MobileNo");
    navigate("/");
    notifyInfo(t("Logout_msg"));
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    props.setLanguage ? props.setLanguage(event.target.value) : null;
  };

  return (
    <>
      <div className="topbar">
        {isLoggedIn ? (
          <div className="toggle" onClick={showSideNav}>
            <div className={`custom_bar ${props.isActive ? "open" : ""}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="logo">MBOA</div>
        )}
        {/* {isLoggedIn ? (
          <div div className="search-box d-none d-md-block">
            <label htmlFor="">
              <input type="text" placeholder="Search here" />
              <i className="fa fa-search" aria-hidden="true"></i>
            </label>
          </div>
        ) : null} */}
        {/* <div className="menus">
          <ul>
            <li onClick={handleHome}>{t("Home")}</li>
            <li onClick={handleAbout}>{t("About")}</li>
          </ul>
        </div> */}
        <div className="d-flex align-items-center gap-2">
          <div className="language-select">
            <div className="d-flex justify-content-end align-items-center language-select-root">
              <FormControl
                sx={{ m: 1, minWidth: 80 }}
                className="custom-language-menu"
              >
                <InputLabel id="demo-simple-select-autowidth-label">
                  <GlobeIcon />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={language}
                  onChange={handleLanguageChange}
                  autoWidth
                  label="Language"
                  className="custom-language-menu_select"
                >
                  {languages.map(({ code, name }) => (
                    <MenuItem
                      key={code}
                      value={code}
                      className="custom-language-menu_options"
                      onClick={() => {
                        i18next.changeLanguage(code);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {isLoggedIn ? (
            <>
              {isLoggedIn && isAdmin ? (
                <button
                  type="button"
                  className="custom-notification-badge flex-shrink-0"
                  onClick={handleNotifications}
                >
                  <div className="notification-icon">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                  </div>
                  <span className="notification-count">
                    {notificationNumber && notificationNumber.totalNotification}
                  </span>
                </button>
              ) : null}
              <div
                className={`account_modal ${isOpen ? "d-block" : "d-none"}`}
                onClick={showDropMenu}
              ></div>
              {props.screen !== "welcomeScreen" ? (
                <div
                  div
                  className="user-box d-flex align-items-center"
                  onClick={showDropMenu}
                >
                  <div className="user-name mx-2 d-none d-md-block">
                    {t("Welcome")}! <br />{" "}
                    {userDetails && userDetails.firstName} &nbsp;
                    {userDetails && userDetails.lastName}
                  </div>
                  <div className="user-img">
                    <img src={userIcon} alt="" />
                  </div>
                  <div
                    className={`user-menus ${isOpen ? "d-block" : "d-none"}`}
                  >
                    <ul>
                      <li>
                        <NavLink
                          to="/profile"
                          onClick={() => props.setProfile("self")}
                        >
                          <i className="fa fa-user-o" aria-hidden="true"></i>{" "}
                          {t("Profile")}
                        </NavLink>
                      </li>
                      <li className="setting_dropdown">
                        <div
                          className="setting_dropdown-btn"
                          onClick={showSetting}
                        >
                          <div>
                            <i className="fa fa-cog" aria-hidden="true"></i>{" "}
                            {t("Settings")}
                          </div>
                          <div>&#8964;</div>
                        </div>
                        <ul
                          className={`child_list ${
                            showSettingMenus ? "show" : ""
                          }`}
                        >
                          <li>
                            <NavLink to="/changePassword">
                              {t("Change_Password")}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div
                          className="logout-button"
                          to="#"
                          onClick={handleLogOut}
                        >
                          <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                          {t("Logout")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="btn_primary" onClick={handleLoginScreen}>
                {t("Login")} / {t("Signup")}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
