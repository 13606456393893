import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Box, Typography } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Modal from "@mui/material/Modal";
import MobileNumberInput from "../mobileNumberInput";
import { notifyError } from "../../utils/notification";
import { isValidPhoneNumber } from "react-phone-number-input";
import { SignUpAPI } from "./../../api/auth";
import { _getProviders } from "../../api/providers";
import { _getPageContent } from "../../api/user";
import cookies from "js-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SignUp() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [termsConditions, setTermsConditions] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [provider, setProvider] = useState("");
  const [values, setValues] = useState([]);
  const [countryCode, setCountryCode] = useState("+237");
  const [language, setLanguage] = useState(cookies.get("i18next") || "fr");
  const [instructions, setInstructions] = useState();

  const closeTermsModal = () => setOpenTermsModal(false);

  const handleChange = (event) => {
    setProvider(event.target.value);
  };

  const handleTermsEvent = () => {
    setTermsConditions(!termsConditions);
  };

  const openModal = () => {
    setOpenTermsModal(true);
  };

  const getProvidersList = () => {
    try {
      _getProviders().then(async (res) => {
        if (res.status === 200) {
          setValues(res.data.data);
          setProvider(res.data.data[0]._id);
        } else {
          setValues([]);
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getProvidersList();
    getTermsConditions();
  }, []);

  const handleVerifyNumber = (e) => {
    e.preventDefault();
    const isValid = isValidPhoneNumber(mobile);
    if (
      !mobile ||
      !password ||
      !provider ||
      !firstName ||
      !lastName ||
      !email
    ) {
      notifyError(t("Enter_all_details"));
    } else {
      if (!termsConditions) {
        notifyError(t("Agree_Terms_Conditions"));
      } else {
        if (password.length <= 6) {
          notifyError(t("Short_Password"));
        } else {
          let requestData = {
            mobile: mobile,
            firstName: firstName,
            lastName: lastName,
            email: email,
            moneyProviderId: provider,
            moneyProviderMobile: mobile,
            countryCode: countryCode,
            password: password,
          };

          SignUpAPI(requestData)
            .then((res) => {
              if (res && res.status === 200 && res.data.success) {
                navigate("/verify", {
                  state: {
                    mobile: mobile,
                    countryCode: countryCode,
                    previousPage: "SignUp",
                  },
                });
              } else {
                console.log("Something went wrong");
              }
            })
            .catch((e) => console.log(e));
        }
      }
    }
  };

  const getTermsConditions = () => {
    try {
      _getPageContent("TC").then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          const formattedInstruction = res.data.data
            ? language === "en"
              ? res.data.data.content[0].content.replace(/(<([^>]+)>)/gi, "")
              : res.data.data.content[1].content.replace(/(<([^>]+)>)/gi, "")
            : "";
          setInstructions(formattedInstruction);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="signup-page py-3">
          <div className="signup-page_box">
            <div className="row">
              <div className="col-md-10 m-auto">
                <div className="right-box">
                  <div className="text-center">
                    <span className="icon-box">
                      <LockOutlinedIcon />
                    </span>
                    <h3>{t("Signup-text")}</h3>
                  </div>
                  <form onSubmit={handleVerifyNumber}>
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <label htmlFor="First Name">{t("First_Name")}: </label>
                        <input
                          autoComplete="fname"
                          name="firstName"
                          variant="outlined"
                          placeholder={t("First_Name")}
                          required
                          id="firstName"
                          label={t("First_Name")}
                          autoFocus
                          value={firstName}
                          onChange={(e) => {
                            let fname = e.target.value;
                            fname = fname.replace(/[^A-Za-z]/gi, "");
                            setFirstName(fname);
                          }}
                          maxLength="20"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="Last Name">{t("Last_Name")}: </label>
                        <input
                          variant="outlined"
                          placeholder={t("Last_Name")}
                          required
                          id="lastName"
                          label={t("Last_Name")}
                          name="lastName"
                          autoComplete="lname"
                          value={lastName}
                          onChange={(e) => {
                            let lname = e.target.value;
                            lname = lname.replace(/[^A-Za-z]/gi, "");
                            setLastName(lname);
                          }}
                          maxLength="20"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">{t("Email_Id")}: </label>
                        <input
                          variant="outlined"
                          required
                          placeholder="example@mail.com"
                          id="email"
                          label={t("Email_Address")}
                          name="email"
                          autoComplete="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>{t("Select_provider")}:</label>
                        <select
                          variant="outlined"
                          required
                          multiline
                          id="provider"
                          value={provider}
                          label={t("Select_provider")}
                          name="provider"
                          onChange={handleChange}
                        >
                          {values.map((value, index) => {
                            return (
                              <option key={index} value={value._id}>
                                {value.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <MobileNumberInput
                          setMobile={setMobile}
                          setCountryCode={setCountryCode}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="password">{t("Password")}:</label>
                        <input
                          variant="outlined"
                          margin="normal"
                          required
                          placeholder={t("Password")}
                          error={isPasswordError}
                          name="password"
                          label={t("Password")}
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            if (e.target.value.length <= 6) {
                              setIsPasswordError(true);
                            } else {
                              setIsPasswordError(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="final-checkbox">
                      <FormControlLabel
                        className="m-0"
                        control={
                          <Checkbox
                            className="p-0"
                            value="agreeTerms"
                            color="primary"
                          />
                        }
                        onChange={(e) => handleTermsEvent(e)}
                      />
                      <div className="final-text">
                        {t("Agree")}{" "}
                        <span onClick={openModal}>{t("Terms_Conditions")}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 m-auto text-center">
                        <button
                          type="submit"
                          className={"btn_primary"}
                          onClick={(e) => handleVerifyNumber(e)}
                        >
                          {t("Signup")}
                        </button>
                      </div>
                    </div>
                    <div className="account-confirm-box mt-3 text-center text-lg-end">
                      {t("Already_account")}?
                      <NavLink to="/login" variant="body2">
                        {t("Login-text")}
                      </NavLink>
                    </div>
                  </form>
                </div>
                <Modal
                  open={openTermsModal}
                  onClose={closeTermsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="tnc-modal-customization"
                >
                  <Box sx={style} className="border-0 custom-outline_none">
                    <div className="modal-icon-theme">
                      <div className="modal-icon">
                        <i className="fa fa-handshake-o" aria-hidden="true"></i>
                      </div>
                    </div>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {t("Terms_Conditions")}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {instructions}
                      <div
                        fullWidth
                        className="btn_primary text-center"
                        onClick={closeTermsModal}
                      >
                        {t("Close")}
                      </div>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
