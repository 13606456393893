export const DynamicUrl = "https://mboa.finance/backend";

const SignUp = DynamicUrl + "/api/user/signup";
const getProviders = DynamicUrl + "/api/user/money-provider";
const verifyPhone = DynamicUrl + "/api/user/mobile/verify";
const sendMobileOTPonLogin = DynamicUrl + "/api/user/send-otp-2FA-login";
const verify2FAlogin = DynamicUrl + "/api/user/verify-2FA-login";
const loginMobile = DynamicUrl + "/api/user/mobile/login";
const userProfile = DynamicUrl + "/api/user/profile";
const userBalance = DynamicUrl + "/api/user/balance";
const getInflationRate = DynamicUrl + "/api/user/inflation-rate";
const changePassword = DynamicUrl + "/api/user/change/password";
const getProviderInstructions = DynamicUrl + "/api/user/moneyprovider";
const sendWithdrawRequest = DynamicUrl + "/api/user/balance/withdraw";
const updateProfile = DynamicUrl + "/api/user/profile/update";
const getAdminDashboardInfo = DynamicUrl + "/api/admin/dashboard/blocks";
const userList = DynamicUrl + "/api/admin/user/list";
const userHistory = DynamicUrl + "/api/admin/user/history";
const getUserTransactions = DynamicUrl + "/api/user/transaction";
const sendOtpResetPassword = DynamicUrl + "/api/user/send-otp-reset-password";
const verifyOtpResetPassword =
  DynamicUrl + "/api/user/verify-otp-reset-password";
const resetPassword = DynamicUrl + "/api/user/reset-password";
const DepositToUser = DynamicUrl + "/api/admin/user/credit";
const getUserTransactionHistoryForAdmin =
  DynamicUrl + "/api/admin/user/transaction";
const getUserDetailsForAdmin = DynamicUrl + "/api/admin/user-detail";
const updateUserProfile = DynamicUrl + "/api/admin/change-user-detail";
const updateInstruction = DynamicUrl + "/api/admin/provider/update-instruction";
const addMoneyProvider = DynamicUrl + "/api/admin/add/moneyprovider";
const updateMoneyProvider = DynamicUrl + "/api/admin/provider/update-name";
const deleteMoneyProvider =
  DynamicUrl + "/api/admin/moneyprovider/change-status";
const adminNotification = DynamicUrl + "/api/admin/notification";
const resendSignupCode = DynamicUrl + "/api/user/mobile/resendcode";
const getAdminTransactions = DynamicUrl + "/api/admin/transaction";
const updateInterestRate = DynamicUrl + "/api/admin/add/inflation";
const getUserRequests = DynamicUrl + "/api/admin/user-request";
const changeUserRequestStatus =
  DynamicUrl + "/api/admin/change-user-request-status";
const blockUnblockUser = DynamicUrl + "/api/admin/user/blocked";
const markNotificationAsRead =
  DynamicUrl + "/api/admin/notification/single-read";
const markAllAsRead = DynamicUrl + "/api/admin/notification/read";
const getActiveUserList = DynamicUrl + "/api/admin/activeuser/list";
const getScreenInstructions = DynamicUrl + "/api/admin/pages/list";
const updateScreenInstructions = DynamicUrl + "/api/admin/pages/update";
const getPageContent = DynamicUrl + "/api/user/page";
const getScreenList = DynamicUrl + "/api/admin/pages/list";
const getGraphData = DynamicUrl + "/api/admin/dashboard/graph";
const validateToken = DynamicUrl + "/api/validate-token";
const checkOtpStatus = DynamicUrl + "/api/user/check-otp-status";

export default {
  SignUp: SignUp,
  getProviders: getProviders,
  verifyPhone: verifyPhone,
  sendMobileOTPonLogin: sendMobileOTPonLogin,
  verify2FAlogin: verify2FAlogin,
  loginMobile: loginMobile,
  userProfile: userProfile,
  userBalance: userBalance,
  getInflationRate: getInflationRate,
  changePassword: changePassword,
  getProviderInstructions: getProviderInstructions,
  sendWithdrawRequest: sendWithdrawRequest,
  updateProfile: updateProfile,
  getAdminDashboardInfo: getAdminDashboardInfo,
  userList: userList,
  userHistory: userHistory,
  getUserTransactions: getUserTransactions,
  sendOtpResetPassword: sendOtpResetPassword,
  verifyOtpResetPassword: verifyOtpResetPassword,
  resetPassword: resetPassword,
  DepositToUser: DepositToUser,
  getUserTransactionHistoryForAdmin: getUserTransactionHistoryForAdmin,
  getUserDetailsForAdmin: getUserDetailsForAdmin,
  updateUserProfile: updateUserProfile,
  updateInstruction: updateInstruction,
  addMoneyProvider: addMoneyProvider,
  updateMoneyProvider: updateMoneyProvider,
  deleteMoneyProvider: deleteMoneyProvider,
  adminNotification: adminNotification,
  resendSignupCode: resendSignupCode,
  getAdminTransactions: getAdminTransactions,
  updateInterestRate: updateInterestRate,
  getUserRequests: getUserRequests,
  changeUserRequestStatus: changeUserRequestStatus,
  blockUnblockUser: blockUnblockUser,
  markNotificationAsRead: markNotificationAsRead,
  markAllAsRead: markAllAsRead,
  getActiveUserList: getActiveUserList,
  getScreenInstructions: getScreenInstructions,
  updateScreenInstructions: updateScreenInstructions,
  getPageContent: getPageContent,
  getScreenList: getScreenList,
  getGraphData: getGraphData,
  validateToken: validateToken,
  checkOtpStatus: checkOtpStatus,
};
