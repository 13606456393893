import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CardBox from "../cardBox";
import Transactions from "../transactions";
import DetailsCard from "../detailsCard";
// import InflationChart from "./chart";
import { _getUserDetails, _getUserTransactions } from "../../api/user";
import { _getAdminDashboardInfo } from "../../api/admin";
import { notifyError } from "../../utils/notification";
import Charts from "./Charts";
import LoaderLayer from "../../components/LoaderLayer";

export default function Dashboard(props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState();
  const [activeLoader, setActiveLoader] = useState(true);
  const [isUser, setIsUser] = useState(false);
  const { t } = useTranslation();

  let formatting_options = {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const getUserProfile = () => {
    try {
      _getUserDetails().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setActiveLoader(false);
          setIsUser(true);
          if (res.data.data.role === "ADMIN") {
            setIsAdmin(true);
            setIsUser(false);
            getAdminDashboardInfo();
          } else {
            setIsAdmin(false);
          }
          setIsAdmin(res.data.data.role === "ADMIN" ? true : false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const getAdminDashboardInfo = () => {
    try {
      _getAdminDashboardInfo().then(async (res) => {
        if (res && res.status === 200 && res.data.success) {
          setDashboardInfo(res.data.data);
          setActiveLoader(false);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      {isAdmin ? (
        <>
          <div className="card-box">
            <CardBox
              heading={t("Number_of_users")}
              cardName="TotalUsers"
              value={dashboardInfo ? dashboardInfo.totalUser : 0}
              icon="fa fa-users"
            />
            <CardBox
              heading={t("New_Requests")}
              cardName="NewRequest"
              value={dashboardInfo ? dashboardInfo.newlUserRequest : 0}
              icon="fa fa-comments"
            />
            <CardBox
              heading={t("Interest_Rate")}
              value={
                dashboardInfo ? dashboardInfo.inflationRate.yearlyInterest : 0
              }
              cardName="InflationRate"
              icon="fa fa-bar-chart"
            />
            <CardBox
              heading={t("Total_Amount")}
              value={
                dashboardInfo
                  ? Number(dashboardInfo.totalAmountDeposite).toLocaleString(
                      "en-US",
                      formatting_options
                    )
                  : 0
              }
              cardName="TotalAmount"
              icon="fa fa-database"
            />
            <CardBox
              heading={t("Total_Interest")}
              // value={dashboardInfo ? Number(dashboardInfo.totalInterest) : 0}
              value={
                dashboardInfo
                  ? Number(dashboardInfo.totalInterest).toLocaleString(
                      "en-US",
                      formatting_options
                    )
                  : 0
              }
              cardName="TotalInterest"
              icon="fa fa-line-chart"
            />
          </div>
          <div className="custom-box-property mt-3">
            <Charts />
          </div>
        </>
      ) : null}
      {isUser ? (
        <>
          <div className="mb-3 ">
            <DetailsCard />
          </div>
          <div className="mt-3">
            <Transactions />
          </div>
        </>
      ) : null}
    </>
  );
}
