import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import cookies from "js-cookie";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { notifyError, notifySuccess } from "../../../utils/notification";
import {
  _getScreenInstructions,
  _updateScreenInstructions,
  _getScreenList,
} from "../../../api/admin";
import Sidebar from "../../sidebar";
import Header1 from "../../header1";
import LoaderLayer from "../../LoaderLayer";

const ManageFooter = () => {
  const confirmSave = useConfirm();
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [instructionScreen, setInstructionScreen] = useState();
  const [instructions, setInstructions] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState([]);
  const [language, setLanguage] = useState(cookies.get("i18next") || "fr");
  const placeHolder = t("Enter_page_instructions");
  const screenData = useRef();
  const [activeLoader, setActiveLoader] = useState(true);

  const handleSave = () => {
    confirmSave({
      title: t("Do_you_really_want_to_save_instructions"),
      confirmationText: t("OK"),
      cancellationText: t("Cancel"),
    })
      .then(() => updateScreenInstructions())
      .catch(() => notifyError(t("Could_not_update_instructions")));
  };

  function handleChange(event) {
    setInstructionScreen(event.target.value);
  }

  useEffect(() => {
    getScreenList();
    // getScreenInstructions();
  }, []);

  useEffect(() => {
    getScreenInstructions();
    getScreenList();
  }, [language]);

  useEffect(() => {
    getScreenInstructions();
  }, [instructionScreen]);

  useEffect(() => {
    setInstructionScreen(
      values.length > 0 && !instructionScreen
        ? values[0]._id
        : instructionScreen
    );
  }, [values]);

  const getScreenInstructions = () => {
    try {
      _getScreenInstructions(language === "en" ? "english" : "french").then(
        async (res) => {
          if (res && res.data.status === 200 && res.data.success) {
            var newArray = res.data.data.filter(function (el) {
              return el._id === instructionScreen;
            });
            setInstructions(newArray.length > 0 ? newArray[0].content : "");
          } else {
            console.log("Something went wrong");
          }
        }
      );
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getScreenList = () => {
    try {
      _getScreenList(language === "en" ? "english" : "french").then(
        async (res) => {
          if (res.status === 200) {
            setValues(res.data.data);
            // setInstructionScreen(res.data.data[0]._id);
            setActiveLoader(false);
          } else {
            setValues([]);
            console.log("Something went wrong");
          }
        }
      );
    } catch (error) {
      console.log("Error:", error);
      setActiveLoader(false);
    }
  };

  const updateScreenInstructions = () => {
    setIsEdit(!isEdit);
    var selectedPage = values.filter(function (el) {
      return el._id === instructionScreen;
    });
    try {
      let requestData = {
        content: screenData.current.state.value,
        id: instructionScreen,
        language: language === "en" ? "ENGLISH" : "FRENCH",
        type: selectedPage[0].type,
      };
      _updateScreenInstructions(requestData).then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          notifySuccess(t("Page_updated_successfully"));
          getScreenInstructions();
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const addInstruction = (e) => {
  //   setInstructions(e);
  // };

  const editInstruction = () => {
    setIsEdit(!isEdit);
  };

  return (
    <>
      <LoaderLayer activeLoaderValue={activeLoader} />
      <div className="page-layout">
        <Sidebar isActive={isActive} setActive={setActive} />
        <div className={`right-side ${isActive ? "jsActive" : ""}`}>
          <Header1
            setActive={setActive}
            isActive={isActive}
            setLanguage={setLanguage}
          />
          <div className="main-content">
            <div className="manage-money-provider-page custom-box-property">
              <div className="">
                <h4 className="page-heading">{t("Manage_Footer")}</h4>
              </div>
              <div className="row mb-3 gy-4">
                <div className="col-md-4">
                  <label htmlFor="">{t("Select_section")} </label>
                  <select
                    variant="outlined"
                    required
                    multiline
                    id="Screen"
                    value={instructionScreen}
                    label="Screen"
                    name="Screen"
                    onChange={handleChange}
                  >
                    {values.map((value, index) => {
                      return (
                        <option key={index} value={value._id}>
                          {value.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-end  gy-3">
                    {isEdit ? null : (
                      <div className="">
                        <label htmlFor="" className="d-none d-md-block">
                          {" "}
                          &nbsp;
                        </label>
                        <button
                          className="btn_primary btn_primary-sm"
                          onClick={editInstruction}
                        >
                          {t("Edit_Instructions")}
                        </button>
                      </div>
                    )}
                    {/* <div>
                      <label htmlFor="" className="d-none d-md-block">
                        {" "}
                        &nbsp;
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
              <div>
                <ReactQuill
                  theme="snow"
                  placeholder={placeHolder}
                  value={instructions ?? ""}
                  ref={screenData}
                  // onChange={(e) => addInstruction(e)}
                  readOnly={isEdit ? false : true}
                />
              </div>
              {isEdit ? (
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn_primary btn_primary-sm"
                    onClick={handleSave}
                  >
                    {t("Save")}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default ManageFooter;
