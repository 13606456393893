import common from "./common";
import axios from "axios";
import { notifyError } from "../utils/notification";
import { t } from "i18next";

export const _getProviders = (data) => {
  var authOptions = {
    method: "GET",
    url: common.getProviders,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _getProviderInstructions = (data) => {
  var authOptions = {
    method: "GET",
    url: `${common.getProviderInstructions}/${data}`,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _updateInstructions = (data) => {
  var authOptions = {
    method: "POST",
    url: common.updateInstruction,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(
        error.response.data.message === "Instruction must be 20 character long."
          ? t("Instruction_length")
          : error.response.data.message
      );
    });
};

export const _addMoneyProvider = (data) => {
  var authOptions = {
    method: "POST",
    url: common.addMoneyProvider,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _updateMoneyProvider = (data) => {
  var authOptions = {
    method: "POST",
    url: common.updateMoneyProvider,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};

export const _deleteMoneyProvider = (data) => {
  var authOptions = {
    method: "POST",
    url: common.deleteMoneyProvider,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: sessionStorage.getItem("Token"),
    },
  };
  return axios(authOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error: ", error);
      notifyError(error.response.data.message);
    });
};
