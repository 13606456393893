import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";

export default function PaginationRounded({
  totalRecord,
  limit,
  setPageNumber,
}) {
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    getCountOfPages();
  });

  function handlePagination(event) {
    setPageNumber(event.currentTarget.textContent);
  }

  const getCountOfPages = () => {
    let total_pages;
    total_pages = Math.ceil(totalRecord / limit);
    setTotalPages(total_pages);
  };

  return (
    <Stack spacing={2}>
      {totalPages && totalPages > 0 ? (
        <Pagination
          count={totalPages}
          shape="rounded"
          onChange={handlePagination}
          hideNextButton={true}
          hidePrevButton={true}
        />
      ) : null}
    </Stack>
  );
}
