import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import heroBg from "../../assets/images/shape-bg-home-hero.svg";
import bannerImg from "../../assets/images/background_contactus.jpg";
import Header1 from "../header1";
import Sidebar from "../sidebar";
import Footer from "./Footer";
import { _getPageContent } from "../../api/user";
import { notifyError } from "../../utils/notification";

export default function TermsConditions() {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [instructions, setInstructions] = useState();
  const [language, setLanguage] = useState(cookies.get("i18next") || "fr");

  useEffect(() => {
    if (sessionStorage.getItem("Token")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    getPageContent();
  }, [language]);

  const getPageContent = () => {
    try {
      _getPageContent("TC").then(async (res) => {
        if (res && res.data.status === 200 && res.data.success) {
          const formattedInstruction = res.data.data
            ? language === "en"
              ? res.data.data.content[0].content //.replace(/(<([^>]+)>)/gi, "")
              : res.data.data.content[1].content //.replace(/(<([^>]+)>)/gi, "")
            : "";
          setInstructions(formattedInstruction);
        } else {
          console.log("Something went wrong");
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="page-layout">
        {isLoggedIn ? (
          <Sidebar isActive={isActive} setActive={setActive} />
        ) : null}
        <div
          className={`${isLoggedIn ? "right-side" : ""} ${
            isActive ? "jsActive" : ""
          }`}
        >
          {isLoggedIn ? (
            <Header1
              setActive={setActive}
              isActive={isActive}
              setLanguage={setLanguage}
            />
          ) : (
            <div className="container">
              <Header1
                setActive={setActive}
                isActive={isActive}
                setLanguage={setLanguage}
              />
            </div>
          )}
          <div className="main-content">
            <div className="landing-page">
              <section className="landing-banner">
                <div className="container">
                  <div className="banner-child">
                    <div className="row no-gutters">
                      <div className="col-md-6">
                        <div className="banner-left text-center text-md-start">
                          <div>
                            <h1>{t("Terms_Service")}</h1>
                            <div
                              className="instructions-list"
                              dangerouslySetInnerHTML={{
                                __html: instructions ? instructions : "",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="banner-right">
                          <img src={bannerImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-bg">
                  <img src={heroBg} alt="" />
                </div>
              </section>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
